import { XaferNotificationCategory } from "../notification.model";


export interface XaferMqttCode {
    code: number;
    event: string;
    name: string;
}

export interface XaferMqttCodeConfig extends XaferMqttCode {
    /**
     * default is 'out'
     */
    direction?: 'in' | 'out';
    /**
     * null for 'direction'=='in' only!
     */
    category: XaferNotificationCategory | null;
    action?: {
        notification: boolean;
        /**
         * default is false
         */
        showKPI?: boolean;

        /**
         * If 'clears' is set, the code is not added to KPI, but remove other codes.
         */
        clears?: number[];
        // expireIn?: number;
    },
}

/**
 * @more https://docs.google.com/spreadsheets/d/1t2igR3cxq5UJ3qSWF_WNi0tJ7ru29leW/edit#gid=1021056525
 *
 * @link XaferMqttCodeConfig
 */
export const mqttCodes = {
    OFFLINE: {
        code: -1,
        event: "OFFLINE",
        name: "MQTT Connection is lost",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ONLINE: {
        code: 0,
        event: "ONLINE",
        name: "Device is connected to MQTT",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: false,
            clears: [-1],
        },
    },

    PWR_OK: {
        code: 1,
        event: "PWR_OK",
        name: "Main power supply",
        category: XaferNotificationCategory.POWER,
        action: {
            notification: false,
            clears: [2],
        },
    },
    ERR_PWR: {
        code: 2,
        event: "ERR_PWR",
        name: "No main power supply",
        category: XaferNotificationCategory.POWER,
        action: {
            notification: true,
            showKPI: true,
        },
    },

    BATT_OK: {
        code: 3,
        event: "BATT_OK",
        name: "Battery power supply active",
        category: XaferNotificationCategory.BATTERY,
        action: {
            notification: false,
            clears: [4],
        },
    },
    BATT_LOW: {
        code: 4,
        event: "BATT_LOW",
        name: "Low battery voltage",
        category: XaferNotificationCategory.BATTERY,
        action: {
            notification: true,
            showKPI: true,
        },
    },

    BATT_TEST_OK: {
        code: 5,
        event: "BATT_TEST_OK",
        name: "Battery test passed",
        category: XaferNotificationCategory.BATTERY,
        action: {
            notification: false,
            clears: [4, 6], // TODO clarify code '4' here
        },
    },
    ERR_BATT_TEST: {
        code: 6,
        event: "ERR_BATT_TEST",
        name: "Battery test failed",
        category: XaferNotificationCategory.BATTERY,
        action: {
            notification: false,
            showKPI: true,
        },
    },

    LTE_OK: {
        code: 7,
        event: "LTE_OK",
        name: "modem LTE registered on GPRS network",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: false,
            clears: [8],
        },
    },
    ERR_LTE: {
        code: 8,
        event: "ERR_LTE",
        name: "modem LTE not registered on GPRS network",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: false,
            showKPI: true,
        },
    },

    WIFI_OK: {
        code: 9,
        event: "WIFI_OK",
        name: "WiFi connected",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: false,
            clears: [10],
        },
    },
    ERR_WIFI: {
        code: 10,
        event: "ERR_WIFI",
        name: "WiFi disconnected",
        category: XaferNotificationCategory.CONNECTION,
        action: {
            notification: false,
            showKPI: true,
        },
    },

    ARMED: {
        code: 11,
        event: "ARMED",
        name: "System is armed",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    DISARMED: {
        code: 12,
        event: "DISARMED",
        name: "System is disarmed",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },

    FOG_READY: {
        code: 13,
        event: "FOG_READY",
        name: "System ready for fog release",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    FOG_RELEASE: {
        code: 14,
        event: "FOG_RELEASE",
        name: "System is releasing fog",
        category: XaferNotificationCategory.SMOKE,
        action: {
            notification: true,
            showKPI: true,
            // expireIn: 24h
        },
    },
    /**
     * @deprecated: code removed
     */
    FOG_24H: {
        code: 15,
        event: "FOG_24H",
        name: "Fog released in the last 24h",
        category: XaferNotificationCategory.SMOKE,
    },


    MAINT_REQ: {
        code: 16,
        event: "MAINT_REQ",
        name: "Planned system maintenance is required",
        direction: 'in',
        category: null,
    },
    MAINT_START: {
        code: 17,
        event: "MAINT_START",
        name: "System maintenance started",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
        },
    },
    MAINT_STOP: {
        code: 18,
        event: "MAINT_STOP",
        name: "System maintenance finished",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
            clears: [33, 34, 35, 36, 37, 38, 39],
        },
    },
    CLEAN_START: {
        code: 19,
        event: "CLEAN_START",
        name: "Cleaning procedure is started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    CLEAN_STOP: {
        code: 20,
        event: "CLEAN_STOP",
        name: "Cleaning procedure is finished",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },

    TAMPER: {
        code: 21,
        event: "TAMPER",
        name: "Tamper open detected",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },

    SP0_GO: {
        code: 22,
        event: "SP0_GO",
        name: "Heating to set point zero started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP0_OK: {
        code: 23,
        event: "SP0_OK",
        name: "Set point zero reached",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP1A_GO: {
        code: 24,
        event: "SP1A_GO",
        name: "Heating to set point 1 armed started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP1A_OK: {
        code: 25,
        event: "SP1A_OK",
        name: "Set point 1 armed reached",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP2A_GO: {
        code: 26,
        event: "SP2A_GO",
        name: "Heating to set point 2 armed started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP2A_OK: {
        code: 27,
        event: "SP2A_OK",
        name: "Set point 2 armed reached",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP1D_GO: {
        code: 28,
        event: "SP1D_GO",
        name: "Heating to set point 1 disarmed started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP1D_OK: {
        code: 29,
        event: "SP1D_OK",
        name: "Set point 1 disarmed reached",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP2D_GO: {
        code: 30,
        event: "SP2D_GO",
        name: "Heating to set point 2 disarmed started",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    SP2D_OK: {
        code: 31,
        event: "SP2D_OK",
        name: "Set point 2 disarmed reached",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    TEMP_OK: {
        code: 32,
        event: "TEMP_OK",
        name: "Right temperature for fog release",
        category: XaferNotificationCategory.STATUS,
        action: {
            notification: false,
        },
    },
    ERR_HEAT: {
        code: 33,
        event: "ERR_HEAT",
        name: "Error during heating procedure",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_LTEMP: {
        code: 34,
        event: "ERR_LTEMP",
        name: "Low temperature for fog release",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_HTEMP: {
        code: 35,
        event: "ERR_HTEMP",
        name: "High temperature - Self protection",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_FLOW: {
        code: 36,
        event: "ERR_FLOW",
        name: "Error on flow",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_T1: {
        code: 37,
        event: "ERR_T1",
        name: "T1 sensor failed",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_T2: {
        code: 38,
        event: "ERR_T2",
        name: "T2 sensor failed",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    ERR_GEN: {
        code: 39,
        event: "ERR_GEN",
        name: "Generic Error occurred",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },
    BAG_SN: {
        code: 40,
        event: "BAG_SN",
        name: "Fluid bag was recognised by transponder",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: false,
            clears: [41],
        },
    },
    ERR_SN: {
        code: 41,
        event: "ERR_SN",
        name: "Fluid bag not recognised by transponder",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: true,
            showKPI: true,
        },
    },

    BAG_OK: {
        code: 42,
        event: "BAG_OK",
        name: "Fluid bag is valid",
        direction: 'in',
        category: null,
    },
    ERR_BAG: {
        code: 43,
        event: "ERR_BAG",
        name: "Fluid bag is not valid",
        direction: 'in',
        category: null,
    },
    BAG_EXP: {
        code: 44,
        event: "BAG_EXP",
        name: "Fluid bag expired",
        direction: 'in',
        category: null,
    },

    FLU_OK: {
        code: 45,
        event: "FLU_OK",
        name: "Fluid level is ok",
        category: null,
        direction: 'in',
    },
    FLU_LOW: {
        code: 46,
        event: "FLU_LOW",
        name: "Fluid in reserve (1 shot available)",
        category: null,
        direction: 'in',
    },
    FLU_EMPTY: {
        code: 47,
        event: "FLU_EMPTY",
        name: "No fluid in the bag",
        category: null,
        direction: 'in',
    },

    ERR_LOG: {
        code: 48,
        event: "ERR_LOG",
        name: "No more free space for System Log",
        category: XaferNotificationCategory.ERROR,
        action: {
            notification: false,
        }
    },


    GET_IOCFG: {
        code: 64,
        event: "GET_IOCFG",
        name: "Get current input/output configuration",
        category: null,
        direction: 'in',
    },
    IOCFG: {
        code: 65,
        event: "IOCFG",
        name: "Get current input/output configuration",
        category: XaferNotificationCategory.STATUS, // TODO: missing in the documentation
        action: {
            notification: false,
        }
    },
    SET_IOCFG: {
        code: 66,
        event: "SET_IOCFG",
        name: "Set input/output configuration",
        category: null,
        direction: 'in',
    },
    GET_LIC: {
        code: 68,
        event: "GET_LIC",
        name: "Get current licence type",
        category: null,
        direction: 'in',
    },
    LIC: {
        code: 69,
        event: "LIC",
        name: "Reply with actual licence type",
        category: XaferNotificationCategory.STATUS, // TODO: missing in the documentation
        action: {
            notification: false,
        }
    },
    SET_LIC: {
        code: 70,
        event: "SET_LIC",
        name: "Set licence type",
        category: null,
        direction: 'in',
    },

    GET_NET: {
        code: 72,
        event: "GET_NET",
        name: "Get current network parameters",
        category: null,
        direction: 'in',
    },
    NET: {
        code: 73,
        event: "NET",
        name: "Reply with actual network parameters",
        category: XaferNotificationCategory.STATUS, // TODO: missing in the documentation
        action: {
            notification: false,
        }
    },
    SET_NET: {
        code: 74,
        event: "SET_NET",
        name: "Set network parameters",
        category: null,
        direction: 'in',
    },

    GET_FW: {
        code: 76,
        event: "GET_FW",
        name: "Get ESP32 firmware version",
        category: null,
        direction: 'in',
    },
    FW: {
        code: 77,
        event: "FW",
        name: "Reply with actual ESP32 firmware version",
        category: XaferNotificationCategory.STATUS, // TODO: missing in the documentation
        action: {
            notification: false,
        }
    },
    FW_AVAIL: {
        code: 78,
        event: "FW_AVAIL",
        name: "New firmware is available",
        category: null,
        direction: 'in',
    },
    NEW_FW: {
        code: 79,
        event: "NEW_FW",
        name: "New firmware downloaded and applied",
        category: XaferNotificationCategory.STATUS, // TODO: missing in the documentation
        action: {
            notification: false,
        }
    },
};


/**
 *
 */
export function getCodeConfig(code: number): XaferMqttCodeConfig | null {
    const codes = mqttCodes as any;
    for (const key in codes) {
        if ((codes[key] as XaferMqttCodeConfig).code === code) {
            return codes[key] as XaferMqttCodeConfig;
        }
    }
    return null;
}
