/**
 * @collection firmware
 */
export interface XaferFirmware {
    /**
     * filename
     * @deprecated not really useful
     */
    name: string;

    /**
     * unknown meaning.
     * It's always {@link XaferFirmwareType.normal}
     */
    type: XaferFirmwareType;

    /**
     * string must have 3 parts.
     * @example: "1.2.3'
     * TODO: temporary optional to not crash WEB part
     */
    version?: string;
    /**
     * TODO: temporary optional to not crash WEB part
     */
    publicUrl?: string;
    /**
     * TODO: temporary optional to not crash WEB part
     */
    md5?: string;
    /**
     * TODO: temporary optional to not crash WEB part
     */
    size?: number;
    /**
     * User ID who has uploaded the data
     * TODO: temporary optional to not crash WEB part
     */
    uploadedBy?: string;

    /**
     * TODO: temporary optional to not crash WEB part
     */
    uploadedAt?: Date;
}

export enum XaferFirmwareType {
    normal = "normal",
    turbo = "turbo",
    demo = "demo",
}


/**
 *
 */
export function parseVersion(version: string): [number, number, number] {
    const parts = (version || '')
        .split('.')
        .map((v: any) => parseInt(v, 10));
    parts[0] = parts[0] || 0;
    parts[1] = parts[1] || 0;
    parts[2] = parts[2] || 0;
    return parts as [number, number, number];
}


/**
 * return:
 *  -  >0 if v1 > v2,
 *  -  <0 if v1 < v2
 *  -  =0 if v1 == v2
 */
export function compareVersions(v1: string, v2: string) {
    const v1p = parseVersion(v1);
    const v2p = parseVersion(v2);

    for (let i = 0; i < 3; i++) {
        if (v1p[i] !== v2p[i]) {
            return v1p[i] - v2p[i];
        }
    }
    return 0;
}
