import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';



/**
 * it's weird that angular don't do it
 */
export function fromControlValue(control: AbstractControl): Observable<any> {
    return control.valueChanges.pipe(
        startWith(null),
        map(() => control.value),
    );
}