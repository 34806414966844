import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';



/**
 *
 */
export function extractRouteData<T>(activatedRoute: ActivatedRoute, property: string): T {
    let snapshot = activatedRoute.snapshot;
    let _fuse = 20;
    while (snapshot && _fuse-- > 0) {
        if (snapshot.data[property]) {
            return snapshot.data[property] as T;
        } else {
            snapshot = snapshot.parent;
        }
    }
    if (_fuse <= 0) {
        console.warn('extractParentData: fuse reached');
    }
    return null;
}


/**
 *
 */
export function extractRouteParam<T = string>(activatedRoute: ActivatedRouteSnapshot, property: string): T | null {
    let snapshot = activatedRoute;
    let _fuse = 20;
    while (snapshot && _fuse-- > 0) {
        if (snapshot.params[property]) {
            return snapshot.params[property] as T;
        } else {
            snapshot = snapshot.parent;
        }
    }
    if (_fuse <= 0) {
        console.warn('extractRouteParam: fuse reached');
    }
    return null;
}
