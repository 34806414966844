/**
 * @module
 * Icons agreement:
 *  - use 'currentColor' as a fill color (thus we can change the icon color by css)
 *  - use the same icon name as it's appeared in zeplin (if possible). Thus we can avoid icon searching.
 */

export const iconsCustom = {
    // demo
    'alarm-status-demo': 'assets/images/demo/alarm-status.svg',
    'devices-status-demo': 'assets/images/demo/devices-status.svg',
    'map-demo': 'assets/images/demo/map.svg',

    //
    'logo': 'assets/images/logo_xafer.svg',

    'clients': 'assets/icons/clients.svg',
    'cannons': 'assets/icons/cannons.svg',
    'devices': 'assets/icons/cannons.svg',
    'technicians': 'assets/icons/technicians.svg',
    'reference': 'assets/icons/reference.svg',
    'referent': 'assets/icons/capo-filiale.svg',
    'capo-filiale': 'assets/icons/capo-filiale.svg',
    'room-manage': 'assets/icons/room-manage.svg',
    'room': 'assets/icons/room.svg',

    // common
    'email': 'assets/icons/email.svg',
    'insert_drive_file': 'assets/icons/common/insert_drive_file.svg',
    'icon-nav-personal-info': 'assets/icons/icon-nav-personal-info.svg',
    'icon-nav-technician-reference': 'assets/icons/icon-nav-technician-reference.svg',

    // navigation
    'location': 'assets/icons/navigation/location.svg',
    'manufacturer': 'assets/icons/navigation/manufacturer.svg',
    'cannon': 'assets/icons/navigation/cannon.svg',
    'customer': 'assets/icons/navigation/customer.svg',
    'installation-company': 'assets/icons/navigation/installation-company.svg',
    'arrow-dropdown': 'assets/icons/arrow-dropdown.svg',
    'arrow-drop-up': 'assets/icons/arrow-drop-up.svg',
    'more-vert': 'assets/icons/navigation/more-vert.svg',

    // fluid-bag
    'fluidbag-full': "assets/icons/fluid-bag/bag-full.svg",
    'fluidbag-expired': "assets/icons/fluid-bag/bag-expired.svg",
    'fluidbag-empty': "assets/icons/fluid-bag/bag-empty.svg",

    //Alarms
    'connection-error': 'assets/icons/alarms/connection-nok.svg',
    'connection-warn': 'assets/icons/alarms/connection-medium.svg',
    'connection-info': 'assets/icons/alarms/connection-ok.svg',

    'liquid-error': 'assets/icons/alarms/liquid-nok.svg',
    'liquid-warn': 'assets/icons/alarms/liquid-medium.svg',
    'liquid-info': 'assets/icons/alarms/liquid-ok.svg',

    'power-error': 'assets/icons/alarms/power-nok.svg',
    'power-warn': 'assets/icons/alarms/power-medium.svg',
    'power-info': 'assets/icons/alarms/power-ok.svg',

    'smoke-error': 'assets/icons/alarms/smoke-nok.svg',
    'smoke-warn': 'assets/icons/alarms/smoke-medium.svg',
    'smoke-info': 'assets/icons/alarms/smoke-ok.svg',

    'error-error': 'assets/icons/alarms/technical-error-nok.svg',
    'error-warn': 'assets/icons/alarms/technical-error-medium.svg',
    'error-info': 'assets/icons/alarms/technical-error-ok.svg',

    'battery-error': 'assets/icons/alarms/battery-nok.svg',
    'battery-warn': 'assets/icons/alarms/battery-medium.svg',
    'battery-info': 'assets/icons/alarms/battery-ok.svg',

    //Alarms
    'connection-nok': 'assets/icons/alarms/connection-nok.svg',
    'connection-medium': 'assets/icons/alarms/connection-medium.svg',
    'connection-ok': 'assets/icons/alarms/connection-ok.svg',
    'liquid-ok': 'assets/icons/alarms/liquid-ok.svg',
    'liquid-medium': 'assets/icons/alarms/liquid-medium.svg',
    'liquid-nok': 'assets/icons/alarms/liquid-nok.svg',
    'power-nok': 'assets/icons/alarms/power-nok.svg',
    'power-medium': 'assets/icons/alarms/power-medium.svg',
    'power-ok': 'assets/icons/alarms/power-ok.svg',
    'smoke-nok': 'assets/icons/alarms/smoke-nok.svg',
    'smoke-medium': 'assets/icons/alarms/smoke-medium.svg',
    'smoke-ok': 'assets/icons/alarms/smoke-ok.svg',
    'technical-error-ok': 'assets/icons/alarms/technical-error-ok.svg',
    'technical-error-medium': 'assets/icons/alarms/technical-error-medium.svg',
    'technical-error-nok': 'assets/icons/alarms/technical-error-nok.svg',
    'battery-ok': 'assets/icons/alarms/battery-ok.svg',
    'battery-medium': 'assets/icons/alarms/battery-medium.svg',
    'battery-nok': 'assets/icons/alarms/battery-nok.svg',


    //Alarms small
    'connection-small-nok': 'assets/icons/alarms-small/connection-nok.svg',
    'connection-small-medium': 'assets/icons/alarms-small/connection-nok-medium.svg',
    'connection-small-ok': 'assets/icons/alarms-small/connection-ok.svg',
    'liquid-small-nok': 'assets/icons/alarms-small/liquid-nok.svg',
    'liquid-small-medium': 'assets/icons/alarms-small/liquid-nok-medium.svg',
    'liquid-small-ok': 'assets/icons/alarms-small/liquid-ok.svg',
    'power-small-nok': 'assets/icons/alarms-small/power-nok.svg',
    'power-small-medium': 'assets/icons/alarms-small/power-nok-medium.svg',
    'power-small-ok': 'assets/icons/alarms-small/power-ok.svg',
    'smoke-small-nok': 'assets/icons/alarms-small/smoke-nok.svg',
    'smoke-small-medium': 'assets/icons/alarms-small/smoke-nok-medium.svg',
    'smoke-small-ok': 'assets/icons/alarms-small/smoke-ok.svg',
    'technical-error-small-nok': 'assets/icons/alarms-small/technical-error-nok.svg',
    'technical-error-small-medium': 'assets/icons/alarms-small/technical-error-nok-medium.svg',
    'technical-error-small-ok': 'assets/icons/alarms-small/technical-error-ok.svg',
    'battery-small-nok': 'assets/icons/alarms-small/battery-nok.svg',
    'battery-small-medium': 'assets/icons/alarms-small/battery-nok-medium.svg',
    'battery-small-ok': 'assets/icons/alarms-small/battery-ok.svg',


    //Flags
    'flag_it': 'assets/images/flags/it.svg',
    'flag_us': 'assets/images/flags/us.svg',
    'flag_de': 'assets/images/flags/de.svg',
    'flag_fr': 'assets/images/flags/fr.svg',
    'flag_es': 'assets/images/flags/es.svg',
    'flag_pt': 'assets/images/flags/pt.svg',
};
