import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { extractCollection, extractCollectionDate, extractDocument, extractDocumentDate } from "utils/firebase-utils";
import { With$Id, XaferDevice, XfrEntityType, XfrManufacturer, XfrService } from '@xafer-types';
import { XfrLocationProvider } from "./location.provider";
import { _FirebaseRefService } from "../services/_firebase-ref.service";

const COLLECTION = 'device';
@Injectable({
    providedIn: 'root'
})
export class XfrDeviceProvider {
    constructor(
        private firestore: AngularFirestore,
        private refService: _FirebaseRefService,
        private locationsService: XfrLocationProvider,
    ) {
    }

    getDeviceRef(deviceId: string) {
        return this.refService.getDeviceRef(deviceId);
    }

    getFirmwareRef(id: string) {
        return this.refService.getFirmwareRef(id);
    }

    getDeviceById$(id: string): Observable<XaferDevice> {
        return this.firestore.
        collection<XaferDevice>('device')
        .doc(id)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }

    /**
     * @description   
     * use for {@link XfrManufacturer.masterUser}, 
     * {@link XfrManufacturer.finalUser} or {@link XfrService.admin} Users only   
     * for other Users use {@link getDevicesByAssocciation$} instead
     */
    getAllDevices$(): Observable<With$Id<XaferDevice>[]> {
        return this.firestore
        .collection<XaferDevice>('device')
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    /**
     * @description use to get company devices
     * @param companyId - associated company $id.  
     * @param type - company type. 
     * 
     * @example
     * {case__1} you have user profile - getDevicesByOwner$(user.owner.ref.id, getUserCompanyRole(user))

     * @example
     * {case__2} you have company profile - getDevicesByOwner$(company.$id, XfrEntityType.installer)
     */
    getDevicesByAssocciation$(companyId: string, type: XfrEntityType): Observable<With$Id<XaferDevice>[]> {
        console.log('getDevicesByAssocciation$', companyId, type);
        return this.firestore.collection<XaferDevice>(COLLECTION, 
            (ref) => ref.where(this.getCompanyAssociations(type), '==', this.getCompanyReference(type, companyId))
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    assignDeviceToRoom(deviceId: string, locationId: string, roomId: string) {
        const deviceRef = this.getDeviceRef(deviceId);

        return deviceRef.update({
            'installation.room.ref': this.locationsService.getRoomRef(locationId, roomId),
        } as Partial<XaferDevice>);
    }

    unassignDeviceRoom(deviceId: string) {
        const deviceRef = this.getDeviceRef(deviceId);

        return deviceRef.update({
            'installation.room.ref': null,
        } as Partial<XaferDevice>);
    }

    private getCompanyAssociations(type: XfrEntityType) {
        switch(type) {
            case XfrEntityType.service:
                return 'manufacture.ref';
            case XfrEntityType.manufacturer:
                return 'manufacture.ref';
            case XfrEntityType.mainDistributor:
                return 'distribution.mainDistributor.ref';
            case XfrEntityType.distributor:
                return 'distribution.distributor.ref';
            case XfrEntityType.installer:
                return 'installation.installer.ref';
            case XfrEntityType.subInstaller:
                return 'installation.subInstaller.ref';
            case XfrEntityType.customer:
                return 'installation.customer.ref';
            default:
                throw new Error("company-type-error: company type is not defined");      
        }
    }

    private getCompanyReference(type: XfrEntityType, id:string) {
        if(type === XfrEntityType.customer) {
            return this.refService.getCustomerRef(id);
        }

        return this.refService.getCompanyRef(id);
    }

    // delete after test!!
    createDevice(device) {
        return this.firestore.collection(COLLECTION).add(device);
    }
}
