import { Route } from '@angular/router';
import { AuthGuard } from './guards';



export const routes: Route[] = [
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./modules/main/main.module').then((m) => m.MainModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
];
