export const APP_ERR_UNKNOWN = 'app.error.unknown';
export const FIREBASE_AUTH_ERR_UNKNOWN = 'firebase-auth.error.unknown';
export const FIREBASE_ERR_UNKNOWN = 'firebase.error.unknown';

export class AppError extends Error {
    constructor (message: string, public appErrCode: string) {
        super(message);
    }
}

export function fromError(error: unknown, appErrCode: string = APP_ERR_UNKNOWN): AppError {
    console.error(error);
    if (error instanceof AppError || Object.prototype.hasOwnProperty.call(error, 'appErrCode')) {
        return error as AppError;
    }
    if (error instanceof Error) {
        Object.assign(error, {appErrCode});
        return error as AppError;
    }
    if (typeof error === 'string') {
        return new AppError(error, appErrCode);
    }
    return new AppError(appErrCode, appErrCode);
}
