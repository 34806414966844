import { Injectable } from '@angular/core';



/**
 */
@Injectable({providedIn: 'root'})
export class FileDownloadProvider {

    /**
     */
    downloadFromUrl(url: string, filename?: string): Promise<any> {
        if (!filename) {
            filename = getFilenameFromUrl(url);
        }
        return fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                return this.downloadBlob(filename, blob);
            });
    }

    /**
     */
    downloadFromString(filename: string, fileData: string): any {
        const blob = new Blob([fileData]);
        return this.downloadBlob(filename, blob);
    }


    /**
     * https://stackoverflow.com/a/9834261/1269984
     */
    downloadBlob(filename: string, data: Blob): any {
        try {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            console.log('downloadFile: done', filename);
        } catch (e) {
            console.error('downloadFile: error', filename, e);
            throw e;
        }
    }


} // -


/**
 * Not very reliable way
 */
function getFilenameFromUrl(url: string): string {
    const u = new URL(url);
    const [m] = decodeURIComponent(u.pathname).match(/[^\/]+$/) || [];
    return m || 'download-' + Date.now();
}
