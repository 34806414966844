import { DocumentReference } from '@firebase/firestore-types';
import { XaferCompany, XaferCompanyRole } from './company.model';
import { XaferCustomer } from './customer.model';
import { XaferLocation } from './location.model';

export enum XfrEntityType {

    /** reserved for application */
    service = 'service',

    manufacturer = 'manufacturer',
   
    mainDistributor = 'mainDistributor',
   
    distributor = 'distributor',
   
    installer = 'installer',

    /**
     * The installation company can use 
     * sub-installers to provide  
     * installation/support services
     */
    subInstaller = 'subInstaller',
   
    customer = 'customer',
};      

export enum XfrUserType {

    /** reserved for application */
    admin = 'admin',   
    
    /** company admin */
    masterUser = 'masterUser',

    /** 
     * other comapany roles
     * but special role in the customer company! (location chief)
     * location chief - person in charge of (customer)location;
     */
    finalUser = 'finalUser',

    /** installation company user */
    technician = 'technician',
};



/**
 * 
 * Use for providers (as argumets)
 * Use for navigation-buttons config
 * Use for component access config
 * 
 *  Example:
 * 
 *  await this.userProvider
 *  .createUser(
 *    this.distributorId, 
 *    val, 
 *    XfrDistributor.finalUser, 
 * );
 * 
 */

/** reserved for application */
export const XfrService = {
    admin: {entity: XfrEntityType.service, type: XfrUserType.admin},
} as const;
 
export const XfrManufacturer = {
    masterUser: {entity: XfrEntityType.manufacturer, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.manufacturer, type: XfrUserType.finalUser},
} as const;

export const XfrMainDistributor = {
    masterUser: {entity: XfrEntityType.mainDistributor, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.mainDistributor, type: XfrUserType.finalUser},
} as const;

export const XfrDistributor = {
    masterUser: {entity: XfrEntityType.distributor, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.distributor, type: XfrUserType.finalUser},
} as const;

export const XfrInstaller = {
    masterUser: {entity: XfrEntityType.installer, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.installer, type: XfrUserType.finalUser},
    technician: {entity: XfrEntityType.installer, type: XfrUserType.technician},
} as const;

export const XfrSubInstaller = {
    masterUser: {entity: XfrEntityType.subInstaller, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.subInstaller, type: XfrUserType.finalUser},
} as const;

export const XfrCustomer = {
    masterUser: {entity: XfrEntityType.customer, type: XfrUserType.masterUser},
    finalUser: {entity: XfrEntityType.customer, type: XfrUserType.finalUser},
} as const;

export type XfrUserRole = 
    /** reserved for application*/
    typeof XfrService[keyof typeof  XfrService] |
    typeof XfrManufacturer[keyof typeof  XfrManufacturer] |
    typeof XfrMainDistributor[keyof typeof  XfrMainDistributor] |
    typeof XfrDistributor[keyof typeof  XfrDistributor] |
    typeof XfrInstaller[keyof typeof  XfrInstaller] |
    typeof XfrSubInstaller[keyof typeof XfrSubInstaller] |
    typeof XfrCustomer[keyof typeof  XfrCustomer];

/**
 * @collection 'users'
 *
 * @more https://solhea.atlassian.net/browse/XXC-3
 */
export interface XaferUser extends XaferAuth, XaferPersonalInfo {
    privacyDoc?: string;
    certification?: string;
    lang?: string;

    doc?: {
        type: XaferDocTypes;
        number: string;
    },

    totalDevices?: number;
    totalLocations?: number;
    
    /** ref to company owner */
    owner: {
        ref: DocumentReference<XaferCompany | XaferCustomer>
    },

    /** status of invintation email */
    invitation?: {
        error: boolean;
        date: Date; 
    };
}

export interface XaferPersonalInfo {
    name: string;
    surname: string;
    phone: string;
    cellPhone?: number;
    avatar?: string;
}

export interface XaferAuth {
    roles: XfrUserRole[];
    email: string;
    creationDate: Date;
}

export interface XaferLocationChief extends XaferUser{
    locationChief: {
        location: {
            ref: DocumentReference<XaferLocation>;
        }
    }
}

export enum XaferDocTypes {
    codiceFiscale = 'codfisc',
    // TODO: add types on demand
}


/**
 * Data which is kept in other records to keep relation to {@link XaferUser}
 */
export interface XaferUserRelation<T extends XaferUser> {
    ref: DocumentReference<T>;
    name: string;
    surname: string;
    email: string;
    phone?: string;
}



export enum XaferInstallerRole {
    senior = 'senior',
    junior = 'junior'
};


export const XAFER_USER_DOCTYPES = Object.values(XaferDocTypes) as XaferDocTypes[];


/**
 * Use to check user permissions
 * 
 * Usages:   
 * Navigation permission layer   
 * Page permissions layer   
 * Element permission layer   
 */
export function isUserAdmin(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.service && r.type === XfrUserType.admin);
}

export function isUserManufacturer(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.manufacturer);
}

export function isUserMainDistributor(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.mainDistributor);
}

export function isUserDistributor(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.distributor);
}

export function isUserInstaller(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.installer);
}

export function isUserSubInstaller(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.subInstaller);
}

export function isUserCustomer(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.customer);
}

export function isUserCustomerLocationChief(user: XaferUser): boolean {
    return user.roles.some(r => r.entity === XfrEntityType.customer && r.type === XfrUserType.finalUser);
}


/**
 * @description   
 * Use for universall components   
 * which implementated for   
 * manufacturer/mainDistr/Dist/Installer/Customer   
 */
export function getCompanyUserRoles(company: XaferCompanyRole) {
    switch(company) {
        case XfrEntityType.manufacturer:
            return XfrManufacturer;

        case XfrEntityType.mainDistributor:
            return XfrMainDistributor;

        case XfrEntityType.distributor:
            return XfrDistributor;

        case XfrEntityType.installer:
            return XfrInstaller;

        case XfrEntityType.subInstaller:
            return XfrSubInstaller;

        case XfrEntityType.customer:
            return XfrCustomer;
    }

    throw Error('company-type-error: update getCompanyRoles function');
}

/**
 * @description   
 * Use for universall components   
 * which implementated for   
 * manufacturer/mainDistr/Dist/Installer/Customer   
 */
export function getUserCompanyRole(user: XaferUser) {

    if(isUserAdmin(user)) {
        return XfrEntityType.service;
    }

    if(isUserManufacturer(user)) {
        return XfrEntityType.manufacturer;
    }

    if(isUserMainDistributor(user)) {
        return XfrEntityType.distributor;
    }

    if(isUserDistributor(user)) {
        return XfrEntityType.distributor;
    }

    if(isUserInstaller(user)) {
        return XfrEntityType.installer;
    }

    if(isUserSubInstaller(user)) {
        return XfrEntityType.subInstaller;
    }

    if(isUserCustomer(user)) {
        return XfrEntityType.customer;
    }


    throw Error('user-type-error: update getUserCompanyRole function');
}
