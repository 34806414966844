/**
 * @see https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
 */

import "reflect-metadata";
import { Subject } from 'rxjs';

/**
 * @decorator
 */
export function Destroy(): PropertyDecorator {
    return function (obj, propertyName) {
        // create event emitter
        const subject = new Subject();
        obj[propertyName] = subject;

        // add destroy logic
        const originalFn = obj['ngOnDestroy'];
        obj['ngOnDestroy'] = () => {
            originalFn && originalFn.call(obj);

            //
            subject.next();
            subject.complete();
        }
    }
}
