import { AbstractControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { FISC_REGEXP } from './fiscale';



export class ShValidators {

    /**
     *
     */
    static phone = Validators.pattern('^(([+]{0,1})([0-9]{2,4})([-\s\/]{0,1})([0-9]{4,8}))?$');

    /**
     *
     */
    static valueInTheList(list: string[]) {
        // tslint:disable-next-line:only-arrow-functions
        return function (control: AbstractControl): ValidationErrors | null {
            if ((list || []).indexOf(control.value) < 0) {
                return {'not-in-list': true, 'value': control.value};
            }
            return null;
        };
    }

    /**
     * Check that input is a non-empty string
     */
    static requiredString(control: AbstractControl): ValidationErrors | null {
        if (!(control.value || '').trim()) {
            return {'required': true};
        }
        return null;
    }

    /**
     *
     */
    static passwordRepeat(name1: string, name2: string) {
        return (group: FormGroup): ValidationErrors | null => {
            if (group.get(name1).value !== group.get(name2).value) {
                return {'password-repeat': true};
            }
            return null;
        }
    }

      // should be the same
//   static sameValues(control1Name: string, control2Name: string) {
//     return  (formGroup: AbstractControl<any, any>): ValidationErrors | null => {

//       let control1Errors :ValidationErrors | null = formGroup.get(control1Name)?.errors || {};
//       let control2Errors :ValidationErrors | null = formGroup.get(control2Name)?.errors || {};

//       if(formGroup.value[control1Name] === '' && formGroup.value[control2Name] === '' ||
//       formGroup.value[control1Name] === formGroup.value[control2Name]) {

//        control1Errors = this.deleteError(control1Errors, 'notSame');
//        control2Errors = this.deleteError(control2Errors, 'notSame'); 

//        formGroup.get(control1Name)?.setErrors(control1Errors);
//        formGroup.get(control2Name)?.setErrors(control2Errors);

//        return null;
//    }


//       control1Errors = this.addError(control1Errors, 'notSame');
//       control2Errors = this.addError(control2Errors, 'notSame');

//       formGroup.get(control1Name)?.setErrors(control1Errors);
//       formGroup.get(control2Name)?.setErrors(control2Errors);

//       return  {notSame: true};
//   }
// }


    /**
     * @see https://en.wikipedia.org/wiki/Italian_fiscal_code
     */
    static fiscale(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        if ((control.value + '').match(FISC_REGEXP)) {
            return null;
        }

        return {'fiscale': true};
    }
}
