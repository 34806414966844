import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    constructor(
        private router: Router,
        private zone: NgZone,
    ) {

        const firstPageReady$ = this.router.events.pipe(
            // wait first page is ready
            filter(e => e instanceof NavigationEnd),
            take(1),
        ).toPromise();

        firstPageReady$.then(() => this.hideSplashScreen());

    }

    // quick solution
    private hideSplashScreen() {
        console.log('SplashScreenService.hideSplashScreen');
        this.zone.runOutsideAngular(() => {
            const el = document.getElementById('splash-screen') as any;
            if (el) {
                el.style.opacity = 0;
                setTimeout(() => {
                    el.remove();
                }, 400);
            }
        });
    }
}
