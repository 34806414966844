import { DocumentReference } from '@firebase/firestore-types';

export type With$Id<T> = T & { $id: string };

export interface Ref<T> {
    ref: DocumentReference<T>;
}


/**
 *
 */
export function deepToDate<T>(obj: T, level = 10): T {
    if (level <= 0) {
        console.warn('deepToDate fuse reached');
        return obj as T;
    }
    if ( !obj) {
        return obj as T;
    }
    if (typeof (obj as any).toDate === 'function') {
        // 'obj' is Timestamp
        return (obj as any).toDate();
    }
    if (typeof (obj as any).collection === 'function') {
        // 'obj' is DocumentReference
        return obj;
    }

    if (Array.isArray(obj)) {
        const copy = [];
        for (const objEl of obj) {
            copy.push(deepToDate(objEl, level - 1));
        }
        return copy as unknown as T;
    } else if (typeof obj === 'object') {
        const copy: any = {};
        for (const key in obj) {
            if ( !Object.prototype.hasOwnProperty.call(obj, key)) {
                continue;
            }
            copy[key] = deepToDate(obj[key], level - 1);
        }
        return copy as T;
    } else {
        // simple type
        return obj as T;
    }
}
