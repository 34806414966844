import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { XfrCustomerProvider } from 'app/providers';
import { XaferCustomer } from '@xafer-types';
import { take } from 'rxjs/operators';



@Injectable()
export class CustomerResolver implements Resolve<XaferCustomer | null> {
    constructor(
        private service: XfrCustomerProvider,
        private router: Router,
    ) {
    }

    async resolve(route: ActivatedRouteSnapshot): Promise<XaferCustomer | null> {
        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras && navigation.extras.state && navigation.extras.state['customer']) {
            const customer = navigation.extras.state['customer'];
            delete navigation.extras.state['customer']; // remove it from the state as it can't be serialized when navigating
            return Promise.resolve(customer);
        }

        const id = route.paramMap.get('customerId');
        if (!id || id === '0') {
            return Promise.resolve(null);
        }
        try {
            const customer = await this.service.getCustomer$(id)
                .pipe(take(1))
                .toPromise();
            return customer;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
