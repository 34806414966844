import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService, TranslocoLoader } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslocoPreloadLangsModule } from '@ngneat/transloco-preload-langs';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}
    
    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslocoPreloadLangsModule.forRoot(['it', 'en'])
    ],
    exports: [
        TranslocoModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English'
                    },
                    {
                        id: 'it',
                        label: 'Italian'
                    }
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production
            })
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        }
    ]
})
export class TranslocoCoreModule {
}
