import { Directive, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



/**
 * @more https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
 */
@Directive({
    selector: '[filePick]',
})
export class FilePickDirective implements OnInit, OnDestroy {

    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
     * NOTE: file extension check is not supported with drag'n'drop
     */
    @Input()
    accept = '*/*';

    @Input()
    multiple = false;

    @Input()
    disabled = false;

    @Output('filePick')
    readonly files = new Subject<File[]>();

    readonly destroy$ = new Subject();

    private _input: HTMLInputElement | undefined = undefined;


    ngOnInit() {
        this.createFileInput();
    }

    ngOnDestroy() {
        this.destroy$.next(null);
        this.destroy$.complete();

        if (this._input) {
            this._input.remove();
        }
    }

    @HostListener('click', ['$event'])
    onClick(e: Event): void {
        if (this.disabled) {
            return;
        }
        // console.log(e);
        // hotfix: pressing 'enter' in a form cause file dialog appearing
        if (e instanceof PointerEvent && !e.pointerType) {
            return;
        }
        // open system file dialog
        if (this._input) {
            this._input.click();
        }
    }

    /**
     * html 'change' event handler
     */
    fileSelected($event: any /*Event*/) {
        const files = $event.target.files;
        const filesArr = Array.prototype.slice.apply(files) as Array<File>;
        this.onFilesSelected(filesArr);

        if (this._input) {
            this._input.value = ''; // clear value, so user can select the same file again =)
        }
    }

    protected onFilesSelected(files: File[]) {
        this.files.next(files);
    }

    private createFileInput() {
        const el = document.createElement('input');
        el.type = 'file';
        el.style.position = 'absolute';
        el.style.right = '100%';

        el.accept = this.accept;

        document.body.append(el);
        this._input = el;

        fromEvent(el, 'change').pipe(
            takeUntil(this.destroy$),
        ).subscribe((e: Event) => this.fileSelected(e));

    }

}
