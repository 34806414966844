import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { XfrAuthProvider } from 'app/providers';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


/** 
 * Not used in the application
 */
@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    
    constructor(
        private snackbar: MatSnackBar,
        private router: Router,
        private authService: XfrAuthProvider,
        private transloco: TranslocoService
    ) {
        // EMPTY
    }

    private async showRoleError(errCode: string) {
        return new Promise<void>((resolve) => {    
            const message = `${this.transloco.translate('auth.errors.invalid-role')}: ${this.transloco.translate(errCode)}`
            const snackbarRef = this.snackbar.open(message, 'OK', {duration: 3000});
            snackbarRef.afterDismissed().subscribe(() => {
                resolve();
            });
            snackbarRef.onAction().subscribe(() => {
                resolve();
            });
        });
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        const allowRoles: Record<string, boolean> = route.data['allowRoles'];
        try {
            const authUser = await this.authService.waitLogin(5000);
            if (!authUser) {
                return this.router.parseUrl('/auth');
            }
            const user = await this.authService.geCurrentUser$().pipe(take(1)).toPromise();
            if (!user || !user.roles) {
                console.error('No roles assigned to user');
                await this.showRoleError('auth.errors.no-role-assigned');
                await this.authService.logout();
                return this.router.parseUrl('/auth');
            }
            if (!allowRoles) {
                return true;
            }
            // if (!user.roles.some((r) => allowRoles[r])) {
            //     // not allowed by ANY role in config
            //     await this.showRoleError('auth.errors.no-allowed-role');
            //     return this.router.parseUrl('');
            // }
            // if (user.roles.some((r) => allowRoles[r] === false)) {
            //     // disallowed by SOME role in config
            //     await this.showRoleError('auth.errors.disallowed-role');
            //     return this.router.parseUrl('');
            // }
            return true;
        } catch (error) {
            await this.showRoleError('auth.errors.role-unknown');
            return this.router.parseUrl('/auth');
        }
    }
}