import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { extractCollection, extractCollectionDate } from 'utils/firebase-utils';
import { With$Id, XaferNotification, XaferNotificationState } from '@xafer-types';
import { sortArray } from "@src/utils";
import { _FirebaseRefService } from '../services/_firebase-ref.service';

const COLLECTION = 'notification';

@Injectable({
    providedIn: 'root'
})
export class XfrNotificationProvider {
    constructor(
        private firestore: AngularFirestore,
        private refService: _FirebaseRefService,
    ) {
    }


    getAllByStatus$(status?: XaferNotificationState): Observable<With$Id<XaferNotification>[]> {
        return this.firestore
            .collection<XaferNotification>(COLLECTION, refFn => {
                let ref: any = refFn;
                if (status) {
                    ref = ref.where('state', '==', status);
                }
                return ref;
            }).snapshotChanges().pipe(
                extractCollection(),
                extractCollectionDate(),
                sortArray((d1, d2) => d2?.datetime?.getTime() - d1?.datetime?.getTime()),
            );
    }

    getAllByDevice$(deviceId: string) {
        return this.firestore.collection<XaferNotification>(COLLECTION, (ref) => ref
            .where("device.ref", "==", this.refService.getDeviceRef(deviceId))
        ).snapshotChanges()
            .pipe(
                extractCollection(),
                extractCollectionDate(),
                sortArray((d1, d2) => d2?.datetime?.getTime() - d1?.datetime?.getTime()),
            );
    }

    getActiveByDevice$(deviceId: string) {
        return this.firestore.collection<XaferNotification>(COLLECTION, (ref) => ref
            .where("device.ref", "==", this.refService.getDeviceRef(deviceId))
            .where('state', '==', XaferNotificationState.OPEN)
        ).snapshotChanges()
            .pipe(
                extractCollection(),
                extractCollectionDate(),
                sortArray((d1, d2) => d2?.datetime?.getTime() - d1?.datetime?.getTime()),
            );
    }
}
