/**
 * @see https://en.wikipedia.org/wiki/Italian_fiscal_code
 */
export interface FiscaleInfo {
  /**
   * Surname (3 letters)
   * the first three consonants of the surname are used.
   */
  surname: string;
  /**
   * First name (3 letters)
   * the first three consonants of the name are used.
   */
  name: string;

  /**
   *
   */
  birthday: Date;

  /**
   *
   */
  gender: 'M' | 'F';

  /**
   * Town of birth (4 alphanumeric characters)
   */
  town: string;

  /**
   * Check character (one letter)
   * TODO: we don't validate checksum
   */
  check: string;
}

//                   1:surname  2:name     3,4,5:birth+gender 6:town 7:check
export const FISC_REGEXP = /^(\w{3})(\w{3})(\d{2})([ABCDEHLMPRST]{1})(\d{2})(\w{4})(\w{1})$/i;

const monthDefinition = {
  'A': 1, // January
  'B': 2, // February
  'C': 3, // March
  'D': 4, // April
  'E': 5, // May
  'H': 6, // June
  'L': 7, // July
  'M': 8, // August
  'P': 9, // September
  'R': 10, // October
  'S': 11, // November
  'T': 12, // December
};

/**
 *
 */
export function parseFiscale(fiscale: string): FiscaleInfo | null {
  if (!fiscale) {
    return null;
  }

  const m = fiscale.match(FISC_REGEXP);
  if (!m) {
    throw new Error('Invalid fiscale number');
  }

  const now = new Date();
  const century = (now.getFullYear() + '').substr(0, 2);

  const birthday = new Date(0);
  // set year
  birthday.setFullYear(parseInt(century + m[3], 10));
  if (birthday.getFullYear() > now.getFullYear()) {
    birthday.setFullYear(birthday.getFullYear() - 100);
  }
  // set month
  const month = monthDefinition[m[4].toUpperCase()];
  birthday.setMonth(month - 1);

  // set day
  birthday.setDate(parseInt(m[5], 10) % 40);

  const gender = parseInt(m[5], 10) >= 40 ? 'F' : 'M';

  return {
    surname: m[1],
    name: m[2],
    birthday: birthday,
    gender: gender,
    town: m[6],
    check: m[7],
  };
}
