import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilePickDirective } from './file-pick/file-pick.directive';
import { FileDownloadProvider } from './service/file-download.provider';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        FilePickDirective,
    ],
    providers: [
        FileDownloadProvider,
    ],
    exports: [
        FilePickDirective,
    ],
})
export class FileCoreUiModule {
}
