import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AppError, fromError } from 'utils/error-utils';
import {
    extractCollection,
    extractCollectionDate,
    extractDocument,
    extractDocumentDate,
    WithImage
} from 'utils/firebase-utils';
import { XfrFileProvider } from './file.provider';
import { XaferCompany, XaferCompanyRole, XaferCustomer, XaferCustomerAssociations, XaferCustomerRelation, XaferLocation, XfrEntityType, XfrUserType, getEntityType } from '@xafer-types';
import { With$Id } from '@xafer-types';
import { XaferUserControlValue } from '../modules/profile-ui';
import { _FirebaseRefService } from '../services/_firebase-ref.service';


/**
 * @deprecated use {@link XaferUserControlValue}
 */
export interface UserFormData {
    name: string,
    surname: string,
    email: string,
    phone?: string,
    avatar?: File | string,
}


@Injectable({
    providedIn: 'root'
})
export class XfrCustomerProvider {
    constructor(
        private firestore: AngularFirestore,
        private refService: _FirebaseRefService,
        private fileService: XfrFileProvider,
    ) {
    }

    getCustomerRef(customerId: string): DocumentReference<XaferCustomer> {
        return this.refService.getCustomerRef(customerId);
    }

    getCustomer$(id: string): Observable<With$Id<XaferCustomer>> {
        return this.firestore
        .doc<XaferCustomer>(`customer/${id}`)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }

    getAllCustomers$(): Observable<With$Id<XaferCustomer>[]> {
        // TODO: apply scope for authorised user
        return this.firestore
        .collection<XaferCustomer>('customer')
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    getCustomerLocations$(customerId: string): Observable<XaferLocation[]> {
        const query: QueryFn = ref => 
            ref.where('customer.ref', '==', this.getCustomerRef(customerId)
        );

        return this.firestore
        .collection<XaferLocation>('/location', query)
        .snapshotChanges()
        .pipe(extractCollection(),extractDocumentDate());
    }

    getLocation$(locationId: string): Observable<XaferLocation> {
        return this.firestore
        .doc<XaferLocation>(`/location/${locationId}`)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }

    /**
     * @description for Installer, Distributor, Manufacturer.   
     */
    getCustomersByOwner$(companyId: string) {
        return this.firestore
        .collection<XaferCustomer>("customer", (ref) =>
        ref.where(`owner.ref`, "==", this.refService.getCompanyRef(companyId))
        )
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }

    /**
     * @description use to get company devices
     * @param companyId - associated company $id.  
     * @param type - associated company type. 
     * 
     * @example
     * {case__1} you have user profile - getCustomersByAssociation$(user.owner.ref.id, getUserCompanyRole(user))

     * @example
     * {case__2} you have company profile - getCustomersByAssociation$(company.$id, XfrEntityType.installer)
     */ 
    getCustomersByAssociation$(companyId: string, companyType: XaferCompanyRole) {
        console.log('getCustomersByAssociation$', companyId, companyType);
        return this.firestore.collection<XaferCustomer>('customer', 
            // (ref) => ref.where('associations.manufacturer.ref', '==', this.refService.getCompanyRef('XAFER'))
            (ref) => ref.where(this.getCustomerRequestField(companyType), '==', this.refService.getCompanyRef(companyId))
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    async addCustomerLocation(location: Omit<XaferLocation, 'customer' | 'referent' | 'deviceCount'>, customer: XaferCustomerRelation): Promise<string> {
        console.log('CustomerService.addCustomerLocation');
        try {
            const saved = await this.firestore
                .collection<XaferLocation>('/location')
                .add({
                    ...location,
                    customer,
                    referent: null,
                    deviceCount: 0,
                });
            return saved.id;
        } catch (error) {
            throw fromError(error)
        }
    }
    
    /**
     * @description 
     * update customer key person   
     * {@link XaferCustomer.referent}   
     * default customer admin  
     * {@link XfrUserType.masterUser}
     */
    async updateCustomerReferent(companyId: string, referent: XaferUserControlValue) {
        const update: Pick<XaferCompany, 'referent'> = {
            referent: {
                ref: this.refService.getUserRef(referent.$id),
                name: referent.name,
                surname: referent.surname,
                email: referent.email,
                phone: referent.phone || null,
            },
        };

        return this.update(companyId, update);
    }

    async updateCustomerLocation(locationId: string, locationPatch: Partial<Omit<XaferLocation, 'customer' | 'referent' | 'deviceCount'>>): Promise<string> {
        console.log('CustomerService.updateCustomerLocation', locationId);
        try {
            await this.firestore
                .collection<XaferLocation>('/location').doc(locationId)
                .update(locationPatch);
            return locationId;
        } catch (error) {
            throw fromError(error)
        }
    }

    async createCustomer(customer: WithImage<Omit<XaferCustomer, 'creationDate'>, 'logo'>, creator: XaferCompany): Promise<string> {
        try {
            const associations = this.getCustomerAssocciations(creator);

            const copy = {
                ...customer, 
                logo: null,
                creationDate: new Date(),
                associations, 
                owner: {ref: this.refService.getCompanyRef(creator.$id)},  
            };
            delete (copy as any).$id;
            const id = await this.create(copy as Partial<XaferCustomer>);

            if (customer.logo instanceof File) {
                const url = await this.fileService.uploadFile(customer.logo, `customer/${customer.vat}`);
                await this.update(id, {logo: url})
            }
            
            return id;

        } catch (error) {
            throw fromError(error)
        }
    }

    async updateCustomer(customerId: string, customer: Partial<XaferCustomer>  & {logo: string | File | undefined }) {
        const copy = {...customer, logo: customer.logo as string | File | undefined,};
        delete (copy as any).$id;

        if (copy.logo  instanceof File) {
            copy.logo = await this.fileService.uploadFile(copy.logo, `customer/${customerId}`);
        }

        return this.update(customerId, copy as Partial<XaferCustomer>);
    }

    private update(id:string, update: Partial<XaferCustomer>) {
        return this.firestore
        .collection('customer')
        .doc(id)
        .update(update);
    }

    private async create(customer: Partial<XaferCustomer>) {
        const {id} = await this.firestore
        .collection('customer')
        .add({...customer});

        return id;
    }

    /**
     * @description 
     * copy information about the company hierarchy   
     * plus expands the information with a link to the creator company
     */
    private getCustomerAssocciations(creator: XaferCompany): XaferCustomerAssociations['associations'] {
        const role = getEntityType(creator);
        const creatorSet = creator.associations;

        let associations: XaferCustomerAssociations['associations'] = {
            manufacturer: {ref: creatorSet?.manufacturer?.ref},
            mainDistributor: {ref: creatorSet?.mainDistributor?.ref},
            distributor: {ref: creatorSet?.distributor?.ref},
            installer: {ref: creatorSet?.installer?.ref},
            

            /** replaces one of the fields or add sunInstaller or technician*/
            [role]: {ref: this.refService.getCompanyRef(creator.$id)},
        };

        for(const key of Object.keys(associations)) {
            if(associations[key].ref === undefined) {
                delete associations[key];
            }
        }

        return associations;
    }

    private getCustomerRequestField(type: XaferCompanyRole) {
        switch(type) {
            case XfrEntityType.manufacturer:
                return 'associations.manufacturer.ref';
            case XfrEntityType.mainDistributor:
                return 'associations.mainDistributor.ref';
            case XfrEntityType.distributor:
                return 'associations.distributor.ref';
            case XfrEntityType.installer:
                return 'associations.installer.ref';
            case XfrEntityType.subInstaller:
                return 'associations.subInstaller.ref';

            default:
                throw new Error("company-type-error: company type is not defined");      
        }
    }
}
