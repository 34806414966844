import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { FileAny, FileInfo } from '@src/file-ui-core/src';

// TODO: COMPONENT NOT FINISHED!!!

/**
 // TODO: mime comparison in not fully functional yet
 */
const types: { [mime: string]: string } = {
    'text/*': 'title',
    'audio/*': 'audiotrack',
    'image/*': 'image',
    'video/*': 'movie',
    '*/*': 'attachment',
};

const noFileIcon = 'cancel';

/**
 * @more https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
 */
@Component({
    selector: 'file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent implements OnChanges {

    @Input()
    file: FileAny | null = null;

    /**
     * show 'delete' button
     */
    @Input()
    controls = false;

    /**
     * when 'true' - replace icon with image preview.
     * This works for images only
     */
    @Input()
    previewImage = true;

    /**
     */
    @Input()
    showName = false;

    /**
     * image size: 'cover' or 'contain'
     */
    @Input()
    size: 'cover' | 'contain' = 'contain';

    @Output()
    deleteClick = new Subject();
    public previewBg: SafeStyle;
    public previewBgStyle: SafeStyle;

    private _url: string;

    /**
     *
     */
    constructor(
        private sanitizer: DomSanitizer,
    ) {
    }

    @HostBinding('class.image-cover')
    get isCover() {
        return this.size === 'cover';
    }

    @HostBinding('class.image-contain')
    get isContain() {
        return this.size === 'contain';
    }

    get downloadUrl(): string {
        return (this.file as FileInfo)?.url;
    }

    get isPreview(): boolean {
        const mimeType = this.fileMime || '';
        return this.previewImage && mimeType.startsWith('image/');
    }

    get fileName() {
        if (!this.file) {
            return null;
        }
        return (this.file as FileInfo).name || (this.file as File).name || (this.file as string);
    }

    get fileMime() {
        if (!this.file) {
            return null;
        }
        return (this.file as FileInfo).type || (this.file as File).type || (this.previewImage ? 'image/jpeg' : 'application/octet-stream');
    }

    get iconName() {
        if (!this.file) {
            return noFileIcon;
        }

        const mimeType = this.fileMime || '';

        // TODO: mime comparison in not fully functional yet
        const mimeMain = (mimeType || '').split('/')[0];
        return types[mimeMain + '/*'] || types['*/*'];
    }

    ngOnChanges(changes: any) {
        if (changes.file) {
            this._updateFilePreview();
        }
    }

    imageLoaded() {
        // HERE we rely on browser cache
        this.previewBgStyle = this.sanitizer.bypassSecurityTrustStyle(`url("${this._url}")`);
    }

    protected _updateFilePreview() {
        let url: string;
        if (!this.file) {
            this.previewBgStyle = null;
            return;
        }

        if ((this.file as FileInfo).url) {
            url = (this.file as FileInfo).url;
        }

        if (this.file instanceof File) {
            // NOTE: every call of 'createObjectURL' creates different url for the same object
            url = URL.createObjectURL(this.file);
        }
        if (typeof this.file === 'string') {
            url = this.file;
        }

        if (this._url !== url) {
            this._url = url;
            this.previewBg = this.sanitizer.bypassSecurityTrustUrl(url);
            this.previewBgStyle = null;
        }
        // return this.sanitizer.sanitize(SecurityContext.STYLE, `url("${url}")`);
    }

}


