import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { extractCollection, extractCollectionDate, extractDocument, extractDocumentDate, FirebaseUpdateRecord, WithImage } from 'utils/firebase-utils';
import { XfrFileProvider } from './file.provider';
import { DocumentReference } from '@firebase/firestore-types';
import { ShCloudFunctionProvider } from '@src/app/services';
import { With$Id, XaferUser, XfrUserRole, XfrUserType, XfrEntityType, XaferCustomerRelation, XaferLocationChief, XfrCustomer } from '@xafer-types';
import { combineLatest } from 'rxjs';
import { XaferUserControlValue } from '../modules/profile-ui';
import { _FirebaseRefService } from '../services/_firebase-ref.service';
import { deleteField } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { filterArray } from '@src/utils';


@Injectable({
    providedIn: 'root'
})
export class XfrUserProvider {
    constructor(
        private firestore: AngularFirestore,
        private refService: _FirebaseRefService,
        private fileService: XfrFileProvider,
        private cloudFunctionProvider: ShCloudFunctionProvider,
    ) {
    }

    getUserRef<T extends XaferUser>(userId: string): DocumentReference<T> {
        return this.refService.getUserRef(userId);
    }

    getUserById$(id: string) {
        return this.firestore.doc<XaferUser>(`users/${id}`)
            .snapshotChanges()
            .pipe(
                extractDocument(),
                extractDocumentDate(),
            );
    }

    /**
     * @description 
     * For Installers only   
     * (by business logic)
     * @param ownerId - user owner id (user creator company)
     * @param company - XfrEntityType enum value  'installer' (for now)
     */
    getCompanyTechnicians$(ownerId: string, company: XfrEntityType.installer) {
        return this.firestore.collection<XaferUser>('users', ref => 
            ref.where('owner.ref', '==', this.refService.getCompanyRef(ownerId))
            .where('roles', 'array-contains', {entity: company, type: XfrUserType.technician})
        )
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }
    
    /**
     * @description
     * Use for all company types  
     * Manufacturer, MainDistributor, Distributor, Installer, Customer
     * 
     * @param companyId - user owner id (user creator company)
     * @param company - XfrEntityType enum value  'manufacturer'/'mainDistributor' /'distributor' /'installer' /'customer', (not service!)
     */
    getCompanyReferents$(companyId: string, company: XfrEntityType) {
        return combineLatest([
            this.getCompanyMasterUsers$(companyId, company),
            this.getCompanyFinalUsers$(companyId, company),
        ]).pipe(map(([mUs, fUs]) => [...mUs, ...fUs]),)
    }

    /**
     * @description
     * Use for all company types  
     * Manufacturer, MainDistributor, Distributor, Installer, Customer
     * 
     * @param companyId - user owner id (user creator company)
     * @param user - XaferUserControlValue
     * @param role - use XfrManufacturer/XfrMainDistributor/... constants to define user role
     * @param company - XfrEntityType enum value  'manufacturer'/'mainDistributor' /'distributor' /'installer' /'customer', (not service!)
     */
    async createUser(companyId: string, user: XaferUserControlValue, role: XfrUserRole) {
        const userData = this.copyUserData(companyId, user, role);
        return this.create(userData);
    }

    /**
     * @description 
     * For Customers only   
     * (by business logic)
     * location chief(referent) - person in charge of location;
     * additional information in user.model
     */
    async createLocationChiefUser(locationId: string, user: XaferUserControlValue, customer: XaferCustomerRelation) {
        const userData = this.copyUserData(customer.ref.id, user, XfrCustomer.finalUser) as XaferLocationChief;

        userData.locationChief = {
            location: {
                ref: this.refService.getLocationRef(locationId),
            },
        };

        return this.create(userData);
    }


    /**
     * @description 
     * For Customers only   
     * (by business logic)   
     * location chief(referent) - person in charge of location;
     * additional information in user.model
     */
    getLocationsReferentsFree$(customerId: string, currentChiefId?: string) {
        return this.getCompanyReferents$(customerId, XfrEntityType.customer).pipe(
            filterArray((u) => {
                return !(u as With$Id<XaferLocationChief>).locationChief?.location?.ref;
            }),
        );
    }

    private async create(user: XaferUser): Promise<With$Id<XaferUser>> {
        const copy = {...user};

        delete copy.avatar;
        delete copy.privacyDoc;
        delete copy.certification;

        copy.creationDate = new Date();

        const referentDoc = await this.firestore
        .collection('users')
        .add(copy);

        const copyImg = {
            avatar: user.avatar || null,
            privacyDoc: user.privacyDoc || null,
            certification: user.certification || null,
        };

        await this.uploadFiles(referentDoc.id, copyImg);
        await referentDoc.update(copyImg);
        await this.sendInvitationEmail(referentDoc.id);

        (user as With$Id<XaferUser>).$id = referentDoc.id;
        return user as With$Id<XaferUser>;
    }

    async update<T extends XaferUser>(userId: string, patch: FirebaseUpdateRecord<With$Id<WithImage<T, 'avatar' | 'privacyDoc' | 'certification'>>>): Promise<any> {
        console.log('UserService.update', userId);
        const copy = {...patch};
        delete copy.$id;
        // delete copy.avatar;

        await this.uploadFiles(userId, copy);

        await this.firestore
        .collection('users')
        .doc(userId)
        .update(copy);
    }

    private copyUserData(companyId:string, user: XaferUserControlValue, role: XfrUserRole): XaferUser {
        return {
            name: user.name,
            surname: user.surname,
            email: user.email,
            phone: user.phone,
            owner: {
                ref: this.getCompanyRef(companyId, role.entity),
            },
            roles: [{...role}],
            creationDate: new Date(),
        };
    }

    private async uploadFiles(userId: string, patch: FirebaseUpdateRecord<WithImage<XaferUser, 'avatar' | 'privacyDoc' | 'certification'>>): Promise<Partial<XaferUser>> {
        // upload avatar
        if (patch.avatar instanceof File) {
            patch.avatar = await this.fileService.uploadFile(patch.avatar, `user/${userId}/avatar`);
        }
        // upload privacy
        if (patch.privacyDoc instanceof File) {
            patch.privacyDoc = await this.fileService.uploadFile(patch.privacyDoc, `user/${userId}/privacy`);
        }
        if (patch.certification instanceof File) {
            patch.certification = await this.fileService.uploadFile(patch.certification, `user/${userId}/certification`);
        }

        return patch as Partial<XaferUser>;
    }

    private getCompanyMasterUsers$(ownerId: string, companyType: XfrEntityType) {
        return this.firestore
        .collection<XaferUser>('users', ref =>
              ref
                .where('owner.ref', '==', this.getCompanyRef(ownerId, companyType)) 
                .where('roles', 'array-contains', {entity: companyType, type: XfrUserType.masterUser})
        )
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }

    private getCompanyFinalUsers$(ownerId: string, companyType: XfrEntityType) {
        return this.firestore
        .collection<XaferUser>('users', ref => 
              ref
                .where('owner.ref', '==', this.getCompanyRef(ownerId, companyType))
                .where('roles', 'array-contains', {entity: companyType, type: XfrUserType.finalUser})
        )
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }

    private getCompanyRef(companyId: string, companyType: XfrEntityType) {
        switch(companyType) {
            case XfrEntityType.customer:
                return this.refService.getCustomerRef(companyId);
            default:
                return this.refService.getCompanyRef(companyId);
        }
    }

    /**
     * @description
     * for custom async valiator 'checkUserEmail'
     * to check uniqueness of email
     */
    checkEmail(email: string) {
        return this.firestore
        .collection<XaferUser>('users', ref => 
            ref.where('email', '==', email)
            .limit(1)
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractDocumentDate());
    }

    /**
     * @description
     * sends a welcome-email
     */
    private sendInvitationEmail(userId: string) {
        console.log('UserService.sendInvitationEmail', userId);
        return this.cloudFunctionProvider.request('post', '/api/email/welcome', {
            id: userId,
        });
    }

    /**
     * @description 
     * not for current user 
     */
    async updateUserEmail(userId: string, email: string) {
        await this.firestore.collection('users').doc(userId).update({
            invitation: deleteField(),
        });

        return this.cloudFunctionProvider.request('post', 'api/email/change', {
            id: userId,
            email: email,
        });
    }
}
