import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { extractCollection, extractCollectionDate, extractDocument, extractDocumentDate } from 'utils/firebase-utils';
import { Observable } from "rxjs";
import { With$Id, XaferFirmware } from "@xafer-types";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { map, take } from "rxjs/operators";
import { environment } from "@src/environments/environment";

interface FirmwareUploadResult {
    file: {
        md5: string;
    };
    validation: { valid: boolean, error?: string };
}

interface FirmwareDeployResult {
    // TODO
}

@Injectable({
    providedIn: 'root'
})
export class XfrFirmwareProvider {
    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private _http: HttpClient,
    ) {
    }

    /**
     *
     */
    getLatestVersion() {
        return this.firestore.collection<XaferFirmware>('firmware', ref => ref
            .orderBy('uploadedAt', 'desc')
            .limit(1)
        )
            .snapshotChanges()
            .pipe(
                extractCollection(),
                extractCollectionDate(),

                map(fwArr => fwArr[0] || null),
                map(fw => fw?.version || '0.0.0'),
            );
    }

    /**
     */
    async uploadFirmware(file: File, md5: string, version: string) {
        const user = await this.fireAuth.authState.pipe(take(1)).toPromise();
        const token = await user.getIdToken();
        const url = environment.functionsHost + '/api/firmware/upload';
        const formData = new FormData();
        formData.set('binary', file);
        formData.set('md5', md5 || '');
        formData.set('version', version || '');
        return this._http.post<FirmwareUploadResult>(url, formData, {headers: {'Authorization': `Bearer ${token}`}}).toPromise();
    }

    /**
     */
    async deployToDevices(firmwareVersion: string, deviceIds: string[]) {
        const user = await this.fireAuth.authState.pipe(take(1)).toPromise();
        const token = await user.getIdToken();
        const url = environment.functionsHost + '/api/firmware/deploy';
        return this._http.post<FirmwareDeployResult>(url, {
            version: firmwareVersion,
            devices: deviceIds,
        }, {headers: {'Authorization': `Bearer ${token}`}}).toPromise();


        // const batch = this.firestore.firestore.batch();
        // deviceIds.forEach((id) => {
        //     const deviceRef = this.getRef(id);
        //     batch.update(deviceRef, {
        //         firmware: {
        //             ref: firmwareRef,
        //             name: firmware.name
        //         },
        //     });
        // })
    }

    /**
     *
     */
    getAllFirmwares$(): Observable<With$Id<XaferFirmware> []> {
        return this.firestore.collection<XaferFirmware>('firmware', ref => ref
            .orderBy('uploadedAt', "desc"))
            .snapshotChanges()
            .pipe(
                extractCollection(),
                extractCollectionDate(),
                // sortArray((d1, d2) => d2?.uploadedAt?.getTime() - d1?.uploadedAt?.getTime()),
            );
    }

    getFirmware$(id: string): Observable<With$Id<XaferFirmware>> {
        return this.firestore
        .collection<XaferFirmware>('firmware')
        .doc(id)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }
}
