import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { XfrUserProvider } from '@src/app/services';
import {
    With$Id,
    XaferLocation,
    XaferLocationChief,
    XaferRoom,
    XaferUserRelation,
    XfrCustomer
} from "@xafer-types";
import {
    extractCollection,
    extractCollectionDate,
    extractDocument,
    extractDocumentDate,
} from "utils/firebase-utils";
import { _FirebaseRefService } from "../services/_firebase-ref.service";


@Injectable({
    providedIn: 'root'
})
export class XfrLocationProvider {
    constructor(
        private firestore: AngularFirestore,
        private refService: _FirebaseRefService,
        private userService: XfrUserProvider,
    ) {
    }

    getLocationRef(locationId: string): DocumentReference<XaferLocation> {
        return this.refService.getLocationRef(locationId);
    }

    getRoomRef(locationId: string, roomId: string): DocumentReference<XaferRoom> {
        return this.refService.getRoomRef(locationId, roomId);
    }

    getLocation$(locationId: string): Observable<With$Id<XaferLocation>> {
        return this.firestore
        .doc<XaferLocation>(`location/${locationId}`)
        .snapshotChanges()
        .pipe(extractDocument(), extractDocumentDate());
    }

    getAllLocations$(): Observable<With$Id<XaferLocation>[]> {
        return this.firestore
        .collection<XaferLocation>('location')
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }

    getLocationRooms$(locationId: string): Observable<With$Id<XaferRoom>[]> {
        return this.firestore
        .collection<XaferRoom>(`location/${locationId}/rooms`)
        .snapshotChanges()
        .pipe(extractCollection(), extractCollectionDate());
    }

    /**
     * @description 
     * For customer locations only   
     */
    async setLocationChief(locationId: string, referent: XaferUserRelation<XaferLocationChief>) {
        console.log('LocationsService.setLocationChief', locationId, referent?.ref?.id);
        const locationRef = this.getLocationRef(locationId);
        await locationRef.update({
            referent,
        });

        await this.userService.update<XaferLocationChief>(referent.ref.id, {
            locationChief: {
                location: {
                    ref: locationRef,
                },
            },
        });
    }

    async setLocationRooms(locationId: string, rooms: With$Id<XaferRoom>[]) {
        console.log('LocationsService.setLocationRooms', locationId);

        const locationRooms = this.firestore.doc<XaferLocation>(`location/${locationId}`).collection('rooms');

        const toDelete = await this.firestore.collection(`location/${locationId}/rooms`).get().toPromise();

        const batch = locationRooms.ref.firestore.batch();
        toDelete.forEach(doc => {
            batch.delete(doc.ref);
        });
        rooms.forEach((room) => {
            const newRoomRef = locationRooms.doc(room.$id).ref;
            const copy = {...room};
            delete copy.$id;
            batch.set(newRoomRef, copy);
        });
        await batch.commit();
    }

    async promoteToChief(userId: string) {
        await this.userService.update(userId, {
            roles: [{...XfrCustomer.finalUser}],
        });
    }
}
