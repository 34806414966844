import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { XfrCustomerProvider } from 'app/providers';
import { XaferLocation } from '@xafer-types';
import { take } from 'rxjs/operators';

@Injectable()
export class LocationResolver implements Resolve<XaferLocation | null> {
    constructor(
        private service: XfrCustomerProvider,
        private router: Router,
        ) {}
        
    async resolve(route: ActivatedRouteSnapshot): Promise<XaferLocation | null> {
        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras && navigation.extras.state && navigation.extras.state['location']) {
            const location = navigation.extras.state['location'];
            delete navigation.extras.state['location']; // remove it from the state as it can't be serialized when navigating
            return Promise.resolve(location);
        }

        const id = route.paramMap.get('locationId');
        if (!id || id === '0') {
            return Promise.resolve(null);
        }
        try {
            const location = await this.service.getLocation$(id).pipe(take(1)).toPromise();
            return location;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
