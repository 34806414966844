import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { extractCollection, extractCollectionDate, extractDocument, extractDocumentDate } from 'utils/firebase-utils';
import { XfrLicense, XfrLicenseStatus } from '../../../../xafer-types/src/license.model';
import { XfrAuthProvider } from './auth.provider';
import { take } from 'rxjs/operators';
import { With$Id, XaferDevice } from '../../../../xafer-types/src';
import { _FirebaseRefService } from '../services/_firebase-ref.service';
import { environment } from '@src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';

const COLLECTION = 'license';

@Injectable({
    providedIn: 'root'
})
export class XfrLicenseProvider {
    constructor(
        private fireAuth: AngularFireAuth,
        private authProvider: XfrAuthProvider,
        private firestore: AngularFirestore,
        private http: HttpClient,
        private refService: _FirebaseRefService,
    ) {
    }

    private getLicenseRef(licenseId: string) {
        return this.refService.getLicenseRef(licenseId);
    }

    getLicenseById$(id: string) {
        return this.firestore
        .collection<XfrLicense>(COLLECTION)
        .doc(id)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }

    /**
     * @description
     *  license statuses(all variants): 
     * {@link XfrLicenseStatus.active}  
     * {@link XfrLicenseStatus.disabled}
     * 
     * to get active license use  {@link getActiveLicenses$} instead
     */
    getAllLicenses$() {
        return this.firestore
        .collection<XfrLicense>(COLLECTION, ref => 
            ref.where('status', 'in', [
                XfrLicenseStatus.active, 
                XfrLicenseStatus.disabled,
            ])
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    /**
     * @description
     * license statuses (not all variants): 
     * {@link XfrLicenseStatus.active}
     * 
     * to get all license use  {@link getAllLicenses$} instead
     */
    getActiveLicenses$() {
        return this.firestore
        .collection<XfrLicense>(COLLECTION, ref => 
            ref.where('status', '==', XfrLicenseStatus.active)
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    async updateLicenseStatus(data: Partial<With$Id<XfrLicense>>, status: XfrLicenseStatus) {
        const copy = {...data, status};
        copy.updateDate = new Date();
        return this.update(copy.$id, copy);
    }

    async createLicense(data: Partial<XfrLicense>) {
        const userRef = await this.authProvider
        .getCurrentUserRef$()
        .pipe(take(1))
        .toPromise();

        const copy: XfrLicense = {
            name: data.name,
            _name: data.name.toLowerCase(),
            status: data.status,
            global: {...data.global},
            io: {...data.io},
            creationDate: new Date(),
            author: {
                ref: userRef,
            },
        };

        return this.create(copy);
    }

    async updateLicense(id: string, data: Partial<XfrLicense>) {
        const copy = {...data};
        if(data.name) {copy._name = data.name.toLowerCase()}
        copy.updateDate = new Date();
        return this.update(id, copy);
    }

    /**
     * @description
     * for custom async valiator 'checkLicenseName'
     * to check uniqueness of license name
     */
    checkLicenseByName(name: string) {
        return this.firestore
        .collection<XfrLicense>(COLLECTION, ref => 
            ref.where('_name', '==', name.toLowerCase())
            .limit(1)
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractDocumentDate());
    }

    private update(id: string, data: Partial<XfrLicense>) {
        return this.firestore
        .collection(COLLECTION)
        .doc(id)
        .update({...data});
    }

    private create(data: XfrLicense) {
        return this.firestore
        .collection(COLLECTION)
        .add({...data});
    }

    /**
     * @description use to update devices license in mqtt
     * @param licenseId license $id
     * @param deviceIds array of device $ids
     * @returns 
     */
    async deployToDevices(licenseId: string, deviceIds: string[]) {
        const user = await this.fireAuth.authState.pipe(take(1)).toPromise();
        const token = await user.getIdToken();
        const url = environment.functionsHost + '/api/license/deploy';

        return this.http.post(url, {
            licenseId,
            devices: deviceIds,
        }, {
            headers: {'Authorization': `Bearer ${token}`}
        }).toPromise();
    }

    /** TEST 
     * @description
     * only for high-level services (create special requests)      
     * do not use directly in components!!!
     */
    getLicenseByQueryFn(queryFn?: QueryFn) {
        return this.firestore
        .collection<XfrLicense>(COLLECTION, queryFn)
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }
}
