import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { XfrNotificationProvider } from 'app/providers';
import { XaferNotification } from '@xafer-types';
import { take } from 'rxjs/operators';


@Injectable()
export class NotificationResolver implements Resolve<XaferNotification[] | null> {
    constructor(
        private notificationProvider: XfrNotificationProvider,
        private router: Router,
    ) {
    }

    async resolve(route: ActivatedRouteSnapshot): Promise<XaferNotification[] | null> {
        const id = route.params['deviceId'];
        if(!id) {
            return null;
        }

        try {
            return await this.notificationProvider
                .getAllByDevice$(id)
                .pipe(take(1))
                .toPromise();
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
