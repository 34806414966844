import { ChangeDetectorRef, inject, ViewRef } from '@angular/core';
import { MonoTypeOperatorFunction, OperatorFunction, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';



export function untilViewDestroyed() {
    const subject = new Subject<void>();

    const viewRef = inject(ChangeDetectorRef) as ViewRef;

    queueMicrotask(() => viewRef.onDestroy(() => {
        subject.next();
        subject.complete();
    }));
    return takeUntil(subject.asObservable());
}


/**
 * @type rx pipe
 */
export function sortArray<T>(compareFn: (a: T, b: T) => number): MonoTypeOperatorFunction<T[]> {
    return map((elements: T[]) => elements.sort(compareFn));
}

/**
 * @type rx pipe
 */
export function filterArray<T>(filterFn: (el: T, i: number, arr: T[]) => boolean): MonoTypeOperatorFunction<T[]> {
    return map((elements: T[]) => elements.filter(filterFn));
}

/**
 * @type rx pipe
 */
export function mapArray<T, R>(mapFn: (el: T, i: number, arr: T[]) => R): OperatorFunction<T[], R[]> {
    return map((elements: T[]) => elements.map(mapFn));
}


/**
 * @type rx pipe
 */
export function tapArray<T, R>(tapFn: (el: T, i: number, arr: T[]) => any): MonoTypeOperatorFunction<T[]> {
    return tap((elements: T[]) => {
        for (let i = 0; i < elements.length; i++) {
            tapFn(elements[i], i, elements);
        }
    });
}


/**
 * @type rx pipe
 */
export function sliceArray<T>(start?: number, end?: number): MonoTypeOperatorFunction<T[]> {
    return map((elements: T[]) => elements.slice(start, end));
}
