import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable({
    providedIn: 'root'
})
export class XaferToastService {
    constructor(
        private snackBar: MatSnackBar,
        private zone: NgZone,
    ) {
    }

    // operationSuccess(operationName?:string): void {
    operationSuccess(customMessage?: string): void {
        const message = 'Saved';

        this.zone.run(() => {
            this.snackBar.open(customMessage || message, null, {
                panelClass: 'snackbar-success',
                duration: 2000,
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
            });
        })
    }
}
