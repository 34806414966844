import { XfrDocumentInformation } from "./document.model";

export enum XfrLicenseStatus {
    active = 'active',
    disabled = 'disabled',
    expired = 'archived',
};

export interface XfrLicense extends XfrDocumentInformation{
    name: string;
    /**
     * to validate uniq license name in lowercase
     * There is no flag in Firebase to allow sorting or filtering to ignore case.
     * https://stackoverflow.com/questions/48096063/cloud-firestore-case-insensitive-sorting-using-query
     */
    _name: string;
    status: XfrLicenseStatus;
    global: XfrLicenseGlobalSettings;
    io: XfrLicenseIoSettings;
};

export interface XfrLicenseGlobalSettings {
    sp0: number;
    sp1a: number;
    sp1d: number;
    sp1h: number;
    sp2a: number;
    sp2d: number;
    sp2h: number;
    fGo: number;
    fEnd: number;
    aGo: number;
    aEnd: number;
    fStop: number;
    eHeat: number;
    eFlow: number;
    pdc: number;
    fluid: number;
    /** Fluid Consumption per shot */
    flShot: number;
};

export interface XfrLicenseIoSettings {
    in1: number;
    in2: number;
    in3: number;
    in4: number;
    in5: number;
    out1: number;
    out2: number;
    out3: number;
};

// interface XfrLicenseLedSettings {
//     rgb_red_fix: boolean;
//     rgb_red_slow_blink: boolean;
//     rgb_red_fast_blink: boolean;
//     rgb_blue_fix: boolean;
//     rgb_blue_slow_blink: boolean;
//     rgb_blue_fast_blink: boolean;
//     rgb_green_fix: boolean;
//     rgb_green_slow_blink: boolean;
//     rgb_green_fast_blink: boolean;
//     rgb_yellow_fix: boolean;
//     rgb_yellow_slow_blink: boolean;
//     rgb_yellow_fast_blink: boolean;
//     rgb_magenta_fix: boolean;
//     rgb_magenta_slow_blink: boolean;
//     rgb_magenta_fast_blink: boolean;
//     rgb_cian_fix: boolean;
//     rgb_cian_slow_blink: boolean;
//     rgb_cian_fast_blink: boolean;
// };


