import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { XfrAuthProvider } from 'app/providers';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: XfrAuthProvider,
    ) {
        // EMPTY
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        try {
            const authUser = await this.authService.waitLogin(5000);
            return authUser ? true : this.router.parseUrl('/auth');
        } catch (error) {
            console.error(error);
            return this.router.parseUrl('/auth');
        }
    }
}