import { AgmCoreModule } from "@agm/core";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { AppComponent } from "app/app.component";
import { environment } from "environments/environment";
import { MarkdownModule } from "ngx-markdown";
import { routes } from "./app.routes";
import { AuthGuard } from "./guards";
import { IconsModule } from "./icons.module";
import { CustomerResolver } from "./resolvers/customer.resolver";
import { LocationResolver } from "./resolvers/location.resolver";
import {
  XfrAuthProvider,
  ShCloudFunctionProvider,
  XfrFileProvider,
} from "./providers";
import { XaferDrawerService } from "./services/drawer.service";
import { TranslocoCoreModule } from "./transloco.module";
import { FileMaterialUiModule } from "@src/file-material-ui/src";
import { ShErrorHandler } from "@src/app/modules/error-handler";
import { FIREBASE_CONFIG } from "@src/app/providers/cloud-function.provider";
import { NgChartsModule } from "ng2-charts";
import { NotificationResolver } from "./resolvers/notification.resolver";
import { MatNativeDateModule } from "@angular/material/core";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
    MarkdownModule.forRoot({}),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAjX-W_DdSI2nC8M57blh6kPe3jCVLB8TM",
      libraries: ["places"],
      apiVersion: "quarterly",
    }),
    MatSnackBarModule,
    MatSidenavModule,
    MatIconModule,
    IconsModule,
    TranslocoCoreModule,
    FileMaterialUiModule,
    NgChartsModule,
    
    MatNativeDateModule,
  ],
  providers: [
    XfrAuthProvider,
    ShCloudFunctionProvider,
    {
      provide: APP_INITIALIZER,
      deps: [XfrAuthProvider],
      useFactory: (auth: XfrAuthProvider) => () => auth.init(),
      multi: true,
    },
    { provide: ErrorHandler, useClass: ShErrorHandler },
    { provide: FIREBASE_CONFIG, useValue: environment.firebaseConfig },
    AuthGuard,
    XfrFileProvider,
    XaferDrawerService,
    CustomerResolver,
    LocationResolver,
    NotificationResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
