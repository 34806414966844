import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseOptions } from 'firebase/app';



export const FIREBASE_CONFIG = new InjectionToken<FirebaseOptions & { firebaseRegion: string }>('FirebaseConfig');

@Injectable({providedIn: 'root'})
export class ShCloudFunctionProvider {

    /**
     *
     */
    constructor(
        @Inject(FIREBASE_CONFIG) private config: FirebaseOptions & { firebaseRegion: string },
        private fireAuth: AngularFireAuth,
    ) {
        // TODO: we can send timezone value to make accurate time calculation
        // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }


    /**
     * Copied from angularfire2 library. Added 'method' parameter
     */
    // tslint:disable-next-line:max-line-length
    public async request<T>(method: 'get' | 'post' | 'put' | 'delete' | string, urlPart: string, bodyData: any = null, headers = {}): Promise<Response> {
        // fix trailing slash
        if (urlPart.substr(0, 1) === '/') {
            urlPart = urlPart.substr(1);
        }

        const user = await this.fireAuth.currentUser;
        const token = user ? await user.getIdToken() : null;

        // dev
        // const url = `http://localhost:5000/${this.config.projectId}/${this.config.firebaseRegion}/${urlPart}`;
        // prod
        let url = `https://${this.config.firebaseRegion}-${this.config.projectId}.cloudfunctions.net/${urlPart}`;

        if (method === 'get' && bodyData) {
            url += this.urlQuery(bodyData);
            bodyData = null;
        }

        console.log('[ShCloudFunctionProvider] requestRaw', method, url);

        // tslint:disable-next-line:max-line-length
        headers = headers || {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';

        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }


        return fetch(url, {
            method: method,
            body: bodyData ? JSON.stringify(bodyData) : null,
            headers: headers,
        }).then(response => {
            // cors shouldn't happen on mobile phone, so the following code is disabled
            // if (response?.type === 'cors' && response.ok === false) {
            //   throw new Error('CORS issue');
            // }
            if (!response.status || response.status >= 400) {
                // error happened
                return response.text().then((text) => {

                    let message: string;
                    let messageCode: string;
                    try {
                        const jsonData = JSON.parse(text);
                        message = jsonData?.message;
                        messageCode = jsonData?.code ? jsonData?.code : null;
                    } catch (e) {
                        // cannot parse json, assume plain text;
                    }

                    const e = new Error(message || text || ('Error ' + response.status));
                    (e as any).code = messageCode || ('http/' + response.status);
                    throw e;
                });
            }
            return response;
        }).catch(e => {
            console.warn(e);
            // go inside angular zone
            return Promise.resolve().then(() => {
                throw e;
            });
        });
    }

    /**
     *
     */
    urlQuery(obj: any): string {
        return '?' + Object.keys(obj)
            .filter(prop => obj[prop] !== undefined)
            .map(prop => {
                return encodeURIComponent(prop) + '=' + encodeURIComponent(obj[prop]);
            }).reduce((acc, curr) => acc + '&' + curr, '');
    }

} // -
