

console.log('[environment] using PROD environment');

export const environment = {
    production: true,
    hmr: false,

    functionsHost: 'https://europe-west1-xafer-xafycloud.cloudfunctions.net/',

    termsLink: "https://www.xafer.com/privacy-policy/",

    firebaseConfig: {
        apiKey: "AIzaSyAL-Nx_Dt9Hpdyz5LPs8OJjHjrJHGhSTQk",
        authDomain: "xafer-xafycloud.firebaseapp.com",
        projectId: "xafer-xafycloud",
        storageBucket: "xafer-xafycloud.appspot.com",
        messagingSenderId: "134653800948",
        appId: "1:134653800948:web:7012e97327ba08967209a1",
        measurementId: "G-MH9SS92CBP",
        firebaseRegion: "europe-west1"
    },
};
