import { Action, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileAny } from '@src/file-ui-core/src';
import { arrayUnion, FieldValue } from '@angular/fire/firestore';
import { deepToDate } from "@xafer-types";



export { FieldValue, arrayUnion };

/**
 * @deprecated moved to 'types'
 */
export type With$Id<T> = T & { $id: string };
/**
 *
 */
export type WithImage<T, P extends keyof T> = Omit<T, P> & { [key in P]?: FileAny };

/**
 *
 */
export function extractDocument<T, E extends Error | undefined = undefined>(throwWhenNotFound?: E): OperatorFunction<Action<DocumentSnapshot<T>>, E extends Error ? With$Id<T> : With$Id<T> | null> {
    return map((snapshot: Action<DocumentSnapshot<T>>) => {
        const data = snapshot.payload.data() as Partial<T>;
        const $id = snapshot.payload.id;
        if (!snapshot.payload.exists && throwWhenNotFound) {
            throw throwWhenNotFound;
        }
        const result = snapshot.payload.exists ? {...data, $id} : null;
        return result as unknown as E extends Error ? With$Id<T> : With$Id<T> | null;
    });
}


/**
 *
 */
export function extractDocumentDate<T>(): MonoTypeOperatorFunction<T> {
    return map((data: T) => {
        return deepToDate(data)
    });
}

/**
 *
 */
export function extractCollectionDate<T>(): MonoTypeOperatorFunction<T[]> {
    return map((data: T[]) => {
        return data.map(i => deepToDate(i));
    });
}

/**
 * firebase do support FieldValue as a value, but don't have the types for it
 */
export type FirebaseUpdateRecord<T> = Partial<{
    [K in keyof T]: T[K] | FieldValue;
}>;

/**
 * use ONLY after .snapshotChanges()
 *
 * .snapshotChanges() returns a DocumentChangeAction[], which contains
 * a lot of information about "what happened" with each change. If you want to get the data and the id use the map operator.
 * https://github.com/angular/angularfire2/blob/7eb3e51022c7381dfc94ffb9e12555065f060639/docs/firestore/collections.md#example
 */
export function extractCollection<T>(): OperatorFunction<DocumentChangeAction<T>[], With$Id<T>[]> {
    return map((actions: DocumentChangeAction<T>[]) => {
        return actions.map(a => {
            const data = a.payload.doc.data() as Partial<T>;
            const $id = a.payload.doc.id;
            return {...data, $id} as unknown as With$Id<T>;
        });
    });
}


const FIREBASE_AUTH_ERRORS = [
    'auth/wrong-password',
    'auth/weak-password',
    'auth/email-already-in-use',
    'auth/invalid-email',
    'auth/quota-exceeded',
    'auth/user-not-found',
    'auth/invalid-phone-number',
    'auth/invalid-verification-code',
    'auth/captcha-check-failed',
    'auth/missing-verification-code',
] as const;

export type AuthErrorCode = `errors.firebase-auth.${typeof FIREBASE_AUTH_ERRORS[number]}`;

export function getFirebaseAuthErrCode(err: any): AuthErrorCode | 'errors.firebase-auth.unknown' {
    if (err && err.code && FIREBASE_AUTH_ERRORS.includes(err.code)) {
        const code = (err.code + '').slice(5);
        return `errors.firebase-auth.${code}` as AuthErrorCode;
    }
    return 'errors.firebase-auth.unknown';
}
