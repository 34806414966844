import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { extractCollection, extractCollectionDate, extractDocument, extractDocumentDate } from 'utils/firebase-utils';
import { With$Id, XaferCompany, XaferCompanyAssociations, XaferCompanyRole, XaferUserRelation, XfrEntityType, XfrUserType, getEntityType } from '@xafer-types';
import { XfrFileProvider } from './file.provider';
import { _FirebaseRefService } from '../services/_firebase-ref.service';
import { XfrAuthProvider } from './auth.provider';
import { combineAll, take } from 'rxjs/operators';
import { XaferUserControlValue } from '../modules/profile-ui';

const COLLECTION = 'company';

@Injectable({
    providedIn: 'root'
})
export class XfrCompanyProvider {
    constructor(
        private firestore: AngularFirestore,
        private fileProvider: XfrFileProvider,
        private authProvider: XfrAuthProvider,
        private refService: _FirebaseRefService,
    ) {
    }

    getCompanyRef(companyId: string): DocumentReference<XaferCompany> {
        return this.refService.getCompanyRef(companyId);
    }

    getById$(id: string): Observable<XaferCompany> {
        return this.firestore
        .collection(COLLECTION)
        .doc<XaferCompany>(id)
        .snapshotChanges()
        .pipe(extractDocument(),extractDocumentDate());
    }

    getAll$(): Observable<XaferCompany[]> {
        // TODO: apply scope for authorised user
        return this.firestore
        .collection<XaferCompany>(COLLECTION)
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    getCompaniesByType$(role: XaferCompanyRole): Observable<With$Id<XaferCompany>[]> {
        return this.firestore.collection<XaferCompany>(COLLECTION, ref => 
            ref.where('role', '==', role)
        )
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    /**
     * @description 
     * get companies by company type (manufacturer/distributor/installer)
     * @param companyId - associated company id
     * @param companyRole - defines owned company type
     */
    getCompaniesByOwner$(companyId: string, companyRole: XaferCompanyRole) {
        return this.firestore.collection<XaferCompany>(COLLECTION, ref => {
            return ref
            .where('owner.ref', '==', this.getCompanyRef(companyId))
            .where('role', '==', companyRole)
        })
        .snapshotChanges()
        .pipe(extractCollection(),extractCollectionDate());
    }

    /**
     * 
     * @description 
     * create new company MainDistirbutor/Distributor/Installer
     * (the role is presented in the component form)
     * 
     */
    async createCompany(company: XaferCompany, owner: XaferCompany) {
        const associations = this.getCompanyAssociations(owner);
        const copy: XaferCompany = {...company, owner: {ref: this.getCompanyRef(owner.$id)}, associations,};
        

        const logo = copy.logo as File | string | null;
        delete copy.$id;
        delete copy.logo;

        const $id = await this.create(copy);
        if(logo instanceof File) {
            const url = await this.saveLogo(logo, copy.vat, $id);
            await this.update($id, {logo: url})

            return {$id, ...copy, logo: url};
        }

        return {$id, ...copy};
    }

    /**
     * @description update exist company
     * @param logoUrl if logo was changed (optional)
     */
    async updateCompany(company: Partial<XaferCompany>, logoUrl?: string) {
        const copy = {...company};
        const $id = copy.$id;
        const logo = copy.logo as File | string | null;

        delete copy.$id;
        delete copy.logo;

        await this.updateLogo(copy.vat, $id, logo, logoUrl);
        return this.update($id, copy);
    }

    /**
     * @description 
     * update company key person   
     * {@link XaferCompany.referent}   
     * default company admin  
     * {@link XfrUserType.masterUser}
     */
    updateCompanyReferent(companyId: string, referent: XaferUserControlValue) {
        const update: Pick<XaferCompany, 'referent'> = {
            referent: {
                ref: this.refService.getUserRef(referent.$id),
                name: referent.name,
                surname: referent.surname,
                email: referent.email,
                phone: referent.phone || null,
            },
        };

        return this.update(companyId, update);
    }

    async create(company: XaferCompany) {
        const {id} = await this.firestore
        .collection(COLLECTION)
        .add({...company});

        return id;
    }

    private update($id: string, update: Partial<XaferCompany>) {
        return this.firestore
        .collection(COLLECTION)
        .doc($id) 
        .update(update);
    }

    private async updateLogo(vat: string, $id: string, logo: File | string | null, logoUrl?: string) {
        if(logo === logoUrl) {
            return;
        }

        if(logoUrl && !logo) {
            await this.deleteLogo(logoUrl);
            await this.update($id, {logo: null});
        }

        if(logo instanceof File) {

            if(logoUrl) {
                await this.deleteLogo(logoUrl);
            }

            const url = await this.saveLogo(logo, vat, $id);
            await this.update($id, {logo: url});
        }
    }

    private saveLogo(logo: File, vat: string, $id: string) {
        return this.fileProvider
        .uploadFile(logo, `${COLLECTION}/${vat}`);
    }

    private deleteLogo(logo: string) {
        return this.fileProvider
        .removeFile(logo);
    }

    /**
     * @description 
     * copy information about the company hierarchy  
     * plus expands the information with a link to the creator company
     */
    private getCompanyAssociations(creator: XaferCompany): XaferCompanyAssociations['associations'] {
        const role = getEntityType(creator);
        const creatorSet = creator.associations;

        let associations: XaferCompanyAssociations['associations'] = {
            manufacturer: {ref: creatorSet?.manufacturer?.ref},
            mainDistributor: {ref: creatorSet?.mainDistributor?.ref},
            distributor: {ref: creatorSet?.distributor?.ref},
            installer: {ref: creatorSet?.installer?.ref},

            /** for companies hierarchy customer level not relevant */ 

            /** replaces one of the fields */
            [role]: {ref: this.getCompanyRef(creator.$id)},
        };

        for(const key of Object.keys(associations)) {
            if(associations[key].ref === undefined) {
                delete associations[key];
            }
        }

        return associations;
    }
}
