import { DocumentReference } from '@firebase/firestore-types';
import { XaferGeolocation } from './location.model';
import { XaferUser, XaferUserRelation, XfrEntityType } from './user.model';
import { Ref } from './utils';
import { XaferCustomer } from './customer.model';

/**
 * Describe a company [manufacturer, mainDistributor, distributor, installer]   
 * for customer use {@link XaferCustomer} model instead
 *
 * @collection 'company'
 */
export interface XaferCompany extends XaferCompanyAssociations{
    $id: string;
    
    name: string;
    vat: string;
    address: XaferGeolocation;
    phone: string;
    email: string;
    logo?: string;
    role: XaferCompanyRole;

    /**
     * usage: stores information
     * about company key-person 
     * who receives all notifications
     * (default masterUser)
     */
    referent?: XaferUserRelation<XaferUser>;

    /** 
     * @deprecated
     * use address instead
     */
    // location: XaferGeolocation;
    activationDate?: Date;

    privacyFile: string;
    certificateFile: string;

    contact: XaferCompanyContact;
    countries?: Array<{
        flag: string;
        name: string;
    }>;

    /**
     * reference to company - creator
     * optional, since this is not relevant
     * for the manufacturer
     */
    owner?: XaferCompanyRelation<XaferCompany>;
}

/**
 *  'service' role
 *  reserved for the technical 
 *  needs of the application
 */
export type XaferCompanyRole = Exclude<keyof typeof XfrEntityType, 'service'>;


/** 
 * @description
 * for MainDistributor / Distrubutor / Installer / SubInstaller
 * for Customer use {@link XaferCustomerAssociations} instead
 * stores information about 
 * the company hierarchy
 * 
*/
export interface XaferCompanyAssociations {
    associations: {
        /**
         * uasge: to display relationships 
         * between different levels of companies hierarchy
         * https://solhea.invisionapp.com/freehand/Xafer-Roles-System-iTrR8d4G2
         * 
         * example: show all distributior customers
         * one request - getCustomersByDistributorId
         * instead of getting all installers, 
         * for each getting all customers
         */
        manufacturer?: XaferCompanyRelation<XaferCompany>;
        mainDistributor?: XaferCompanyRelation<XaferCompany>;
        distributor?: XaferCompanyRelation<XaferCompany>;
        installer?: XaferCompanyRelation<XaferCompany>;
    };
};

export interface XaferCompanyContact extends Ref<XaferUser> {
    email: string;
    phone?: string;
};


export interface XaferCompanyRelation<T> {
    ref: DocumentReference<T>;
};


/**
 * @description   
 * Use for universal components / methods   
 * which implemented for   
 * manufacturer/mainDistr/Dist/Installer/Customer   
 */
export function getEntityType(company: any) {
    const role = company.role as any as XaferCompanyRole | undefined;

    if(!role) {return XfrEntityType.customer}

    switch(role) {
        case XfrEntityType.manufacturer:
            return XfrEntityType.manufacturer;
        case XfrEntityType.mainDistributor:
            return XfrEntityType.mainDistributor;
        case XfrEntityType.distributor:
            return XfrEntityType.distributor;
        case XfrEntityType.installer:
            return XfrEntityType.installer;
        case XfrEntityType.subInstaller:
            return XfrEntityType.subInstaller;
    }

    throw Error('company-role-error: update getEntityType()');
}


