import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FileCoreUiModule } from '@src/file-ui-core/src';
import { FileDropzoneComponent } from './file-dropzone/file-dropzone.component';



@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,

        FileCoreUiModule,
    ],
    declarations: [
        FilePreviewComponent,
        FileDropzoneComponent,
    ],
    providers: [],
    exports: [
        FilePreviewComponent,
        FileDropzoneComponent,

        FileCoreUiModule,
    ],
})
export class FileMaterialUiModule {
}
