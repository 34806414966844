import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';



/**
 * require 'MatSnackBarModule'
 */
@Injectable({providedIn: 'root'})
export class ShErrorHandler implements ErrorHandler {

    constructor(
        private snackBar: MatSnackBar,
        private zone: NgZone,
    ) {
    }

    handleError(err: any): void {
        // unfold promise rejection
        const e = (err as any).rejection || err;

        console.error(e.code, e);

        // do something with the error

        //
        // Sentry.captureException(e.originalError || e);
        this.zone.run(() => {
            this.snackBar.open(e.message || e, null, {
                panelClass: 'snackbar-error',
                duration: 2000,
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
            });
        })
    }
}
