import { Injectable } from '@angular/core';
import { XaferCompany, XaferCustomer, XaferDevice, XaferLocation, XaferRoom, XaferUser, XfrLicense } from '../../../../xafer-types/src';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { XfrFluidBag } from '../../../../xafer-types/src/fluid-bag.model';


/**
 *  Only for PROVIDERS!!!
 */
@Injectable({
    providedIn: 'root'
})
export class _FirebaseRefService {
    constructor(
        private firestore: AngularFirestore,
    ) {
    }

    getCompanyRef(companyId: string): DocumentReference<XaferCompany> {
        return this.firestore.doc<XaferCompany>(`/company/${companyId}`).ref;
    }

    getCustomerRef(customerId: string): DocumentReference<XaferCustomer> {
        return this.firestore.doc<XaferCustomer>(`/customer/${customerId}`).ref;
    }

    getDeviceRef(deviceId: string) {
        return this.firestore.doc<XaferDevice>(`/device/${deviceId}`).ref;
    }

    getFirmwareRef(id: string) {
        return this.firestore.collection<XaferDevice>('firmware').doc(id).ref;
    }

    getUserRef<T extends XaferUser>(userId: string): DocumentReference<T> {
        return this.firestore.doc<T>(`/users/${userId}`).ref;
    }

    getLicenseRef(licenseId: string): DocumentReference<XfrLicense> {
        return this.firestore.doc<XfrLicense>(`/license/${licenseId}`).ref;
    }

    getFluidBagRef(fluidBagId: string): DocumentReference<XfrFluidBag> {
        return this.firestore.doc<XfrFluidBag>(`/fluidBag/${fluidBagId}`).ref;
    }

    getLocationRef(locationId: string): DocumentReference<XaferLocation> {
        return this.firestore.doc<XaferLocation>(`location/${locationId}`).ref;
    }

    getRoomRef(locationId: string, roomId: string): DocumentReference<XaferRoom> {
        return this.firestore.doc<XaferRoom>(`location/${locationId}/rooms/${roomId}`).ref;
    }
}
