import { DocumentReference } from '@firebase/firestore-types';
import { XaferDevice } from './device/device.model';
import { XaferMqttCode } from './device/InputOutput';
import { XaferUser } from './user.model';
import { XaferCustomer } from './customer.model';
import { XaferLocation } from './location.model';

export interface XaferNotification {

    $id: string;

    /**
     * log from MQTT
     */
    mqtt: XaferMqttCode;

    /**
     * creation date
     */
    datetime: Date;

    archive?: {
        /**
         * User who acknowledged notification
         */
        ref: DocumentReference<XaferUser>;
        date: Date;
    },

    /**
     * Got from mqttCodes, For example: {@link mqttCodes.OFFLINE.category}
     */
    category: XaferNotificationCategory;

    /**
     * Got from mqttCodes, For example: {@link mqttCodes.OFFLINE.importance}
     * @deprecated notifications are now separated from KPI, so it has no 'level'
     */
    level?: XaferNotificationLevel;

    /**
     * use to get unread notifications
     */
    state: XaferNotificationState;

    device: {
        ref: DocumentReference<XaferDevice>;
        name: string;
    };

    /**
     * device location
     */
    location?: {
        ref: DocumentReference<XaferLocation>;
        name: string;
    };

    /**
     * device customer
     * @deprecated notification target is not defined by this property anymore
     */
    customer?: {
        ref: DocumentReference<XaferCustomer>;
        name: string;
    }

}

export enum XaferNotificationState {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum XaferNotificationLevel {
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
}

export enum XaferNotificationCategory {
    CONNECTION = 'connection',
    LIQUID = 'liquid',
    BATTERY = 'battery',
    ERROR = 'error', // OTHER
    POWER = 'power',
    SMOKE = 'smoke',
    STATUS = 'status', // this category is not present in the overview
}

export const ALL_CATEGORIES = [
    XaferNotificationCategory.CONNECTION,
    XaferNotificationCategory.LIQUID,
    XaferNotificationCategory.ERROR,
    XaferNotificationCategory.BATTERY,
    XaferNotificationCategory.SMOKE,
    XaferNotificationCategory.POWER,
];

/**
 *
 */

const _levelPriority = [
    XaferNotificationLevel.INFO,
    XaferNotificationLevel.WARN,
    XaferNotificationLevel.ERROR,
];

export function maxLevel(...levels: XaferNotificationLevel[]): XaferNotificationLevel {
    const index = Math.max(...levels.map(l => _levelPriority.indexOf(l)));
    return _levelPriority[index] || null;
}
