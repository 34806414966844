<mat-icon *ngIf="!isPreview">{{iconName}}</mat-icon>
<mat-spinner *ngIf="isPreview && !previewBgStyle" diameter="24" class="preview-spinner"></mat-spinner>
<div *ngIf="isPreview" class="preview" [style.background-image]="previewBgStyle"></div>

<div class="name ellipsis"
     [class.name--controls]="controls"
     *ngIf="showName"
>{{ fileName }}</div>

<!--
TODO: (angular 8) we need to update angular version to fix 'tabindex' issue
https://github.com/angular/components/issues/12041
-->
<a mat-flat-button color="accent" class="download"
   *ngIf="downloadUrl"
   tabindex="-1"
   [href]="downloadUrl"
   target="_blank">
    <mat-icon>cloud_download</mat-icon>
</a>

<button mat-flat-button class="delete" color="warn" *ngIf="controls" tabindex="-1" (click)="deleteClick.next($event)">
    <mat-icon>delete</mat-icon>
</button>


<img hidden
     decoding="async"
     fetchpriority="low"
     [src]="previewBg"
     (load)="imageLoaded()"
     (error)="previewImage = false"/>
