interface IOCode {
  code: number;
  event: string;
  name: string;
}

export const inputCodes = {
  IN_DISABLED: {code: 0, event: 'IN_DISABLED', name: 'Input disabled'},
  ARMED_LOW: {code: 1, event: 'ARMED_LOW', name: 'System armed if low'},
  ARMED_HIGH: {code: 2, event: 'ARMED_HIGH', name: 'System armed if high'},
  FOG_EN_LOW: {code: 3, event: 'FOG_EN_LOW', name: 'Fog enabled if high'},
  FOG_EN_HIGH: {code: 4, event: 'FOG_EN_HIGH', name: 'Fog enabled if low'},
  TAMPER_LOW: {code: 5, event: 'TAMPER_LOW', name: 'Tamper open if low'},
  TAMPER_HIGH: {code: 6, event: 'TAMPER_HIGH', name: 'Tamper open if high'},
  MAINTENANCE_LOW: {code: 7, event: 'MAINTENANCE_LOW', name: 'System maintenance if low'},
  MAINTENANCE_HIGH: {code: 8, event: 'MAINTENANCE_HIGH', name: 'System maintenance if high'},
};

export const inputCodesArr = Object.values(inputCodes) as IOCode[];

export const outputCodes = {
  OUT_DISABLED: {code: 0, event: "OUT_DISABLED", name: "Output disabled"},
  PWR_OK: {code: 1, event: "PWR_OK", name: "Main power supply"},
  ERR_PWR: {code: 2, event: "ERR_PWR", name: "No main power supply"},
  BATT_OK: {code: 3, event: "BATT_OK", name: "Battery power supply active"},
  BATT_LOW: {code: 4, event: "BATT_LOW", name: "Low battery voltage"},
  BATT_TEST_OK: {code: 5, event: "BATT_TEST_OK", name: "Battery test passed"},
  ERR_BATT_TEST: {code: 6, event: "ERR_BATT_TEST", name: "Battery test failed"},
  LTE_OK: {code: 7, event: "LTE_OK", name: "modem LTE registered on GPRS network"},
  ERR_LTE: {code: 8, event: "ERR_LTE", name: "modem LTE not registered on GPRS network"},
  WIFI_OK: {code: 9, event: "WIFI_OK", name: "WiFi connected"},
  ERR_WIFI: {code: 10, event: "ERR_WIFI", name: "WiFi disconnected"},
  ARMED: {code: 11, event: "ARMED", name: "System is armed"},
  DISARMED: {code: 12, event: "DISARMED", name: "System is disarmed"},
  FOG_READY: {code: 13, event: "FOG_READY", name: "System ready for fog release"},
  FOG_RELEASE: {code: 14, event: "FOG_RELEASE", name: "System is releasing fog"},
  FOG_24H: {code: 15, event: "FOG_24H", name: "Fog released in the last 24h"},
  MAINT_REQ: {code: 16, event: "MAINT_REQ", name: "Planned system maintenance is required"},
  MAINT_START: {code: 17, event: "MAINT_START", name: "System maintenance started"},
  MAINT_STOP: {code: 18, event: "MAINT_STOP", name: "System maintenance finished"},
  CLEAN_START: {code: 19, event: "CLEAN_START", name: "Cleaning procedure is started"},
  CLEAN_STOP: {code: 20, event: "CLEAN_STOP", name: "Cleaning procedure is finished"},
  TAMPER: {code: 21, event: "TAMPER", name: "Tamper open detected"},
  SP0_GO: {code: 22, event: "SP0_GO", name: "Heating to set point zero started"},
  SP0_OK: {code: 23, event: "SP0_OK", name: "Set point zero reached"},
  SP1A_GO: {code: 24, event: "SP1A_GO", name: "Heating to set point 1 armed started"},
  SP1A_OK: {code: 25, event: "SP1A_OK", name: "Set point 1 armed reached"},
  SP2A_GO: {code: 26, event: "SP2A_GO", name: "Heating to set point 2 armed started"},
  SP2A_OK: {code: 27, event: "SP2A_OK", name: "Set point 2 armed reached"},
  SP1D_GO: {code: 28, event: "SP1D_GO", name: "Heating to set point 1 disarmed started"},
  SP1D_OK: {code: 29, event: "SP1D_OK", name: "Set point 1 disarmed reached"},
  SP2D_GO: {code: 30, event: "SP2D_GO", name: "Heating to set point 2 disarmed started"},
  SP2D_OK: {code: 31, event: "SP2D_OK", name: "Set point 2 disarmed reached"},
  TEMP_OK: {code: 32, event: "TEMP_OK", name: "Right temperature for fog release"},
  ERR_HEAT: {code: 33, event: "ERR_HEAT", name: "Error during heating procedure"},
  ERR_LTEMP: {code: 34, event: "ERR_LTEMP", name: "Low temperature for fog release"},
  ERR_HTEMP: {code: 35, event: "ERR_HTEMP", name: "High temperature - Self protection"},
  ERR_FLOW: {code: 36, event: "ERR_FLOW", name: "Error on flow"},
  ERR_T1: {code: 37, event: "ERR_T1", name: "T1 sensor failed "},
  ERR_T2: {code: 38, event: "ERR_T2", name: "T2 sensor failed "},
  ERR_GEN: {code: 39, event: "ERR_GEN", name: "Generic Error occurred "},
  BAG_SN: {code: 40, event: "BAG_SN", name: "Fluid bag was recognised by transponder"},
  ERR_SN: {code: 41, event: "ERR_SN", name: "Fluid bag not recognised by transponder"},
  BAG_OK: {code: 42, event: "BAG_OK", name: "Fluid bag is valid"},
  ERR_BAG: {code: 43, event: "ERR_BAG", name: "Fluid bag is not valid"},
  BAG_EXP: {code: 44, event: "BAG_EXP", name: "Fluid bag expired"},
  FLU_OK: {code: 45, event: "FLU_OK", name: "Fluid level is ok"},
  FLU_LOW: {code: 46, event: "FLU_LOW", name: "Fluid in reserve (1 shot available)"},
  FLU_EMPTY: {code: 47, event: "FLU_EMPTY", name: "No fluid in the bag"},
  ERR_LOG: {code: 48, event: "ERR_LOG", name: "No more free space for System Log"},
};


export const outputCodesArr = Object.values(outputCodes) as IOCode[];
