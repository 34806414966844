import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';



/**
 * @internal
 */
interface DragFileInfo {
    kind: 'file';
    /**
     * File MIME type
     */
    type: string;
}

/**
 * @more https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
 */
@Component({
    selector: 'file-dropzone',
    templateUrl: './file-dropzone.component.html',
    styleUrls: ['./file-dropzone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropzoneComponent implements OnInit, OnChanges {

    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
     * NOTE: file extension check is not supported with drag'n'drop
     */
    @Input()
    accept = '*/*';

    @Input()
    multiple = false;

    @HostBinding('attr.disabled')
    @Input()
    disabled = false;

    @Input()
    tabindex = 0;

    @Output()
    readonly files = new Subject<File[]>();

    /**
     * - none - no interaction
     * - success - all dragged files can be dropped
     * - warn - some dragged files can be dropped
     * - error - none of dragged files can be dropped
     * - loading - open files dialog is loading
     */
    public state: 'none' | 'success' | 'error' | 'warn' | 'loading' = 'none';


    @ViewChild('fileInput', {static: true}) fileInput!: ElementRef;
    private acceptRegArr!: RegExp[];

    /**
     *
     */
    constructor(
        private chref: ChangeDetectorRef,
    ) {
    }

    @HostBinding('attr.tabindex')
    get _tabindex() {
        return this.disabled ? -1 : this.tabindex;
    }

    @HostBinding('class.drop-valid')
    get isValid() {
        return this.state === 'success';
    }

    @HostBinding('class.drop-warn')
    get isWarn() {
        return this.state === 'warn';
    }

    @HostBinding('class.drop-error')
    get isError() {
        return this.state === 'error';
    }

    ngOnInit() {
        this.acceptRegArr = createAcceptRegExp(this.accept || '');
    }

    ngOnChanges(changes: any) {
        if (changes.accept) {
            this.acceptRegArr = createAcceptRegExp(this.accept || '');
        }
    }

    @HostListener('keydown.space', ['$event'])
    @HostListener('keydown.enter', ['$event'])
    @HostListener('click', ['$event'])
    onClick(e: Event): void {
        // console.log(e);
        // hotfix: pressing 'enter' in a form cause file dialog appearing
        if (e instanceof PointerEvent && !e.pointerType) {
            return;
        }
        if (this.disabled) {
            return;
        }
        this.state = 'loading';
        // open system file dialog
        this.fileInput.nativeElement.click();
        setTimeout(() => {
            this.state = 'none';
            this.chref.markForCheck();
        }, 1000);
    }

    @HostListener('drop', ['$event'])
    onDrop($event: DragEvent): void {
        if (this.disabled) {
            return;
        }
        $event.preventDefault();
        const files = extractFiles($event);
        this.onFilesSelected(files);
        this.state = 'none';
    }


    @HostListener('dragover', ['$event'])
    onDragOver($event: DragEvent): void {
        if (this.disabled) {
            return;
        }
        // Prevent default behavior (Prevent file from being opened)
        $event.preventDefault();

        // protect from null
        const dataTransfer = $event.dataTransfer || ({} as DataTransfer);

        // we cannot use extractFiles here, because file data is not available in 'dragover'
        const items = (dataTransfer.items || []) as DataTransferItemList;
        const filesInfo = Array.prototype.slice.apply(items) as Array<DragFileInfo>;

        const filtered = this.filterFileTypes(filesInfo);
        if (filtered.length === 0) {
            this.state = 'error';
            dataTransfer.dropEffect = 'none';
        } else if (filtered.length !== filesInfo.length) {
            this.state = 'warn';
            dataTransfer.dropEffect = 'copy';
        } else {
            this.state = 'success';
            dataTransfer.dropEffect = 'copy';
        }

    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event: DragEvent): void {
        $event.preventDefault();
        this.state = 'none';
    }

    /**
     * html 'change' event handler
     */
    fileSelected($event: any /*Event*/) {
        this.state = 'none';
        const files = $event.target.files;
        const filesArr = Array.prototype.slice.apply(files) as Array<File>;
        this.onFilesSelected(filesArr);
        this.fileInput.nativeElement.value = ''; // clear value, so user can select the same file again =)
    }

    protected onFilesSelected(files: File[]) {
        const filtered = this.filterFileTypes(files);
        // console.log('fileSelected', filtered);
        this.files.next(filtered);
    }


    /**
     * @param files
     * @protected
     */
    protected filterFileTypes<T extends DragFileInfo | File>(files: T[]): T[] {
        return files.filter(f => {
            for (let i = 0; i < this.acceptRegArr.length; i++) {
                if ((f.type || '').match(this.acceptRegArr[i])) {
                    return true;
                }
            }
            return false;
        });
    }
}

/**
 * Create regexp to check file mime types
 *
 * @protected
 */
function createAcceptRegExp(accept: string): RegExp[] {
    return (accept || '')
        .split(',')
        .map(typeStr => {
            // create regexp to verify file type
            if (typeStr.startsWith('.')) {
                // file extension (".doc")
                // return new RegExp('^.*' + typeStr + '$');
                return null; // TODO filename is not available during 'dragover', so we ignore this
            } else {
                // file mime ("image/*")
                return new RegExp('^' + typeStr
                    .replace(/\*/g, '[^\/]*')
                    .replace(/\//g, '\/') + '$');
            }
        }).filter(v => !!v) as RegExp[];
}


/**
 * Extract file info from 'drop' event
 *
 * @more: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop#process_the_drop
 * @protected
 */
function extractFiles(ev: DragEvent): File[] {
    if (ev.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        const files: File[] = [];
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[i].kind === 'file') {
                const file = ev.dataTransfer.items[i].getAsFile() as File;
                // console.log('... file[' + i + '].name = ' + file.name);
                files.push(file);
            }
        }
        return files;
    } else if (ev.dataTransfer?.files) {
        // Use DataTransfer interface to access the file(s)
        const files = [];
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
            // console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
            files.push(ev.dataTransfer.files[i]);
        }
        return files;
    } else {
        console.error('no file api');
        return [];
    }
}
