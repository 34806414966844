import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";

@Injectable({
    providedIn: 'root'
})
export class XfrFileProvider {
    constructor(
        private storage: AngularFireStorage
        ) {
    }

    uploadFile(file: File, folder?: string): Promise<string> {
        const uploadPath = folder ? `${folder}/${file.name}` : file.name;
        console.log('[XaferFileService] uploadFile', uploadPath);

        const storageRef = this.storage.ref(uploadPath);
        const task = storageRef.put(file)

        return task.then(_ => storageRef.getDownloadURL().toPromise());
    }

    async removeFile(fileUrl: string) {
        const ref = this.getRefFromUrl(fileUrl);
        console.log('[XaferFileService] removeFile', ref.fullPath);
        const storageRef = this.storage.ref(ref.fullPath);
        return storageRef.delete().toPromise();
    }

    getRefFromUrl(url: string) {
        return this.storage.storage.refFromURL(url);
    }
}
