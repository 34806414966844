import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, Injectable, InjectionToken, Injector, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal/portal';



export const DRAWER_DATA = new InjectionToken<any>('DRAWER_DATA');

type DrawerPortal = TemplatePortal | ComponentPortal<unknown>;

interface DrawerHandler<R> {
    onClosed: Observable<R | null>;
}

@Injectable({
    providedIn: 'root'
})
export class XaferDrawerService {
    public readonly openedDrawers$ = new BehaviorSubject<DrawerPortal[]>([]);
    public readonly count$: Observable<number>;
    public disablePop = false;

    private readonly closedDrawer$ = new Subject<{ portal: DrawerPortal; data: any }>();

    private _viewRef: ViewContainerRef;

    /**
     *
     */
    constructor(
        private injector: Injector,
        private applicationRef: ApplicationRef,
    ) {
        this.count$ = this.openedDrawers$.pipe(
            map(drawers => drawers.length),
        )

        // TODO: unstable way
        this._viewRef = (this.applicationRef.components[0].instance as any).viewRef;
    }

    /**
     *
     */
    setDisablePop(value: boolean) {
        this.disablePop = value;
    }

    openFromParent<D = any, R = any>(viewRef: ViewContainerRef, component: ComponentType<any>, data?: D): DrawerHandler<R> {
        const portalInjector = Injector.create({
            providers: [
                {provide: DRAWER_DATA, useValue: data},
            ],
            parent: this.injector,
        });

        // Alternate `ComponentFactoryResolver` to use when resolving the associated component.
        // Defaults (null) to using the resolver from the outlet that the portal is attached to, so 
        // additional Material modules (which are available in lazy modules wont be available)
        const resolver = viewRef.injector.get(ComponentFactoryResolver);
        const portal = new ComponentPortal(component, viewRef, portalInjector, resolver);
        return this.push<R>(portal);
    }

    /**
     * @deprecated plz openFromParent
     */
    open<D = any, R = any>(component: ComponentType<any>, data?: D): DrawerHandler<R> {

        const portalInjector = Injector.create({
            providers: [
                {provide: DRAWER_DATA, useValue: data},
            ],
            parent: this.injector,
        });
        const roomsPortal = new ComponentPortal(component, this._viewRef, portalInjector);
        return this.push<R>(roomsPortal);
    }

    /**
     *
     */
    push<R>(drawer: TemplatePortal | ComponentPortal<unknown>): DrawerHandler<R> {
        const drawers = [...this.openedDrawers$.value, drawer];
        this.openedDrawers$.next(drawers);

        return {
            onClosed: this.closedDrawer$.pipe(
                filter(closed => closed.portal === drawer),
                map(closed => closed.data as R),
                take(1),
            ),
        }
    }

    /**
     *
     */
    pop(data?: any) {
        if (!this.disablePop && this.openedDrawers$.value.length) {
            const drawers = this.openedDrawers$.value;
            const portal = drawers.pop();
            if (portal) {
                portal.detach();
                this.closedDrawer$.next({portal, data});
                this.openedDrawers$.next([...drawers]);
            }
        }
    }
}
